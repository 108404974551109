import React, { useEffect } from "react";
import { useNavigate } from 'react-router-dom'
// reactstrap components
import Slider from "react-slick";
import NextArrow from "../SliderNav/NextNav";
import PrevArrow from "../SliderNav/PrevNav";
const RecentWorkThumb = ({ data, itemSHow, itemSHowMD, itemSHowsm, itemSHowXS }) => {
  let items = data;
  const settings = {
    dots: true,
    infinite: true,
    draggable: true,
    rows: 2,
    speed: 500,
    slidesToShow: itemSHow,
    slidesToScroll: 1,
    autoplay: true,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: itemSHowMD,
          slidesToScroll: 1,
          initialSlide: 0
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: itemSHowsm,
          slidesToScroll: 1,
          initialSlide: 0,
          rows: 1,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: itemSHowXS,
          slidesToScroll: 1,
          initialSlide: 0,
          rows: 1,
        }
      }
    ]
  };
  let navigate = useNavigate()
  const goToDetails = (id) => {
    navigate('/product-details/' + id)
  }
  return (
    <div className="row">
      <div className="col-12">
        <div>
          <Slider {...settings}>
            {items.map((item, index) => {
              return <div className="px-1 text-left" key={index}>
                <div className="cursor-pointer" onClick={(e) => goToDetails(item.productID)}>
                  <div className="mb-2 overflow-hidden position-relative">
                    <img alt="" width="100%" className="opacity" src="images/thumb-container.png" />
                    <img alt={item.thumbAlt} width="100%" className="position-absolute start-0 top-0" src={item.thumb} />
                  </div>
                  <div className="h5 mb-0 text-danger">{item.name}</div>
                  <div className="mb-3">Location - {item.location}</div>
                </div>
              </div>
            })}
          </Slider>
        </div>
      </div>

    </div>
  );
}
export default RecentWorkThumb;

import React from "react";
import { Helmet } from "react-helmet-async";
import { copy } from 'copy-anything'
import history from "../history";
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardImg,
  FormGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
  Modal, ModalHeader, ModalBody, ModalFooter
} from "reactstrap";

// core components
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import SimpleFooter from "components/Footers/SimpleFooter.js";
import Team from "../components/Team/Index.js";

class Terms extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      teamlist: [],
    }

  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    
    return (
      <>
        <DemoNavbar />
        <main ref="main">
          <section
          style={{backgroundImage:"url(images/inner-banner-bg.webp)", backgroundPosition:"center", backgroundSize:"cover"}}
          className="section pageTitleBG">
            <div className="row m-0">
              <div className="col-12">
                <h3 style={{fontWeight:"500"}} className="h1 text-center text-uppercase mb-0">Terms & Conditions</h3>
              </div>
            </div>
          </section>
          <section className="section border-top">
            <Container fluid>
              <div className="row align-items-center upPrivacy">

              <div className="col-12 text-center">
                  <p>
                  Welcome to Porinoy - wedding card selling website! Before using our services, please read and understand the following terms and conditions:

                  </p>
                </div>
                <div className="col-12 ">
                  <ul>
                    <li>
                      <span>1. Acceptance of Terms:</span>  By accessing or using our website, you agree to be bound by these terms and conditions. If you do not agree with any part of these terms, please refrain from using our services.
                    </li>
                    <li>
                      <span>2. Order Acceptance:</span>   We will only accept orders for wedding cards if the marriage date is at least 30 days from the date of order placement. This ensures that we have sufficient time to process and deliver your order.
                    </li>
                    <li>
                      <span>3. Payment Terms:</span>    To confirm your order, we require a 50% advance payment before delivery. The remaining balance must be paid before the shipment of the wedding cards. We accept various payment methods, and you agree to provide accurate and complete payment information.
                    </li>
                    <li>
                      <span>4. Matter Correction:</span>   We understand that mistakes can happen during the printing process. Therefore, we allow a maximum of three times matter correction per order. Any additional corrections may incur additional charges.
                    </li>
                    <li>
                      <span>5. Minimum Order Quantity:</span>  The minimum order quantity for wedding cards is 25 pieces. We do not accept orders for quantities below this minimum threshold.
                    </li>
                    <li>
                      <span>6. Pricing and Availability:</span>   All prices listed on our website are in the currency specified and are subject to change without notice. We make every effort to ensure the availability of our products, but in rare cases, certain designs may become unavailable. In such instances, we will notify you and provide alternative options or a refund.

                    </li>
                    <li>
                      <span>7. Shipping and Delivery:</span>    We will make reasonable efforts to ensure timely delivery of your wedding cards. However, we are not responsible for any delays or damages caused by third-party shipping carriers.

                    </li>
                    <li>
                      <span>8. Intellectual Property:</span>   All content on our website, including text, images, logos, and trademarks, is protected by intellectual property laws. You may not use, reproduce, or distribute any content from our website without our prior written consent.

                    </li>
                    <li>
                      <span>9. Privacy Policy:</span>    We respect your privacy and handle your personal information in accordance with our privacy policy. Please review our privacy policy to understand how we collect, use, and protect your information.

                    </li>
                    <li>
                      <span>10. Modifications:</span>   We reserve the right to modify or update these terms and conditions at any time without prior notice. It is your responsibility to review these terms periodically for any changes.

                    </li>
                   
                  </ul>
                  <p>
                  By using our website, you acknowledge that you have read, understood, and agreed to these terms and conditions. If you have any questions or concerns, please contact our customer support team.

                  </p>
                </div>
              </div>
            </Container>
          </section>
         
        </main >
        <SimpleFooter />

      </>
    );
  }
}

export default Terms;

import React from "react";

function CategoryFilter({ data,applyFilter }){
const changeFilter =(e,ind)=>{
    let datas = data;
    datas[ind].checked = !datas[ind].checked;
    applyFilter(datas)
}

    return (
        <>
            <div>
                <div className="h5">
                    Select Category
                </div>
                <div>
                    {data.map((item, index) => {
                        let ifChecked = "";
                        if(item.checked){
                            ifChecked = "bg-danger text-white"
                        }
                        return <span onClick={(e)=>changeFilter(e,index)} className={"d-inline-block border border-danger p-3 me-2 rounded h6 my-1 text-capitalize cursor-pointer "+ifChecked} key={index}>
                            {item.name}
                        </span>
                    })}
                </div>
            </div>
        </>
    )

}
export default CategoryFilter;
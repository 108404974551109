import React, { useEffect, useState } from "react";
// reactstrap components
import Slider from "react-slick";
import {
  Button,
  Container,
  Modal, ModalHeader, ModalBody, ModalFooter
} from "reactstrap";
const Testimonial = ({ data, itemSHow, itemSHowMD, itemSHowsm, itemSHowXS }) => {
  const [modal, setModal] = useState(false)
  const [selected, setSelected] = useState(null)
  const settings = {
    dots: false,
    infinite: true,
    draggable: true,
    speed: 500,
    slidesToShow: itemSHow,
    slidesToScroll: 1,
    autoplay: true,
    centerPadding: '50px',
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: itemSHowMD,
          slidesToScroll: 1,
          initialSlide: 0
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: itemSHowsm,
          slidesToScroll: 1,
          initialSlide: 0
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: itemSHowXS,
          slidesToScroll: 1,
          initialSlide: 0
        }
      }
    ]
  };
  const openModal = (index) => {
    setSelected(index)
    setModal(true)
  }
  const closeModal = () => {
    setSelected(null)
    setModal(false)
  }

  return (
    <>
      <div className="row">
        <div className="col-12 h-100">
          <div className="testimonialslide">
            <Slider {...settings}>
              {data.map((item, index) => {
                let ifLocation = false;
                if (item.clientLocation !== '') {
                  ifLocation = true
                }
                return <div className="p-3 h-100" key={index}> <div className="card h-100 shadow2">
                  <div className="card-body h-100 text-center">
                    <div className="row h-100 align-items-center" >
                      <div className="col-12">
                        <div className="row" >
                          <div className="col-12">
                            <img className="rounded-circle d-inline" style={{ width: '100px' }} src={item.clientPhoto} />
                          </div>
                          <div className="col-12 mt-4">
                            {/* <span style={{ marginLeft: "-20px", marginTop: "-10px", lineHeight: "normal" }} className="h1 mb-0 position-absolute">&ldquo; </span> */}
                            <div>
                              <p
                                className="block-ellipsis"

                              ><em>{item.description}</em></p>
                            </div>
                            {/* <span style={{ marginLeft: "10px", marginTop: "10px", lineHeight: "normal" }} className="h1   position-absolute"> &rdquo;</span> */}
                          </div>
                          <div className="col-12 mt-4">
                            {item.clientName} {ifLocation && <>({item.clientLocation})</>}
                          </div>
                          <div className="col-12 mt-2">
                            @{item.plateform}
                          </div>
                          <div className="col-12 mt-2">
                            <button onClick={() => openModal(index)} className="btn btn-danger">View Detail </button>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
                </div>
              })}
            </Slider>
          </div>
        </div>

      </div>
      <Modal centered isOpen={modal} toggle={closeModal}>
        <ModalHeader toggle={closeModal}>
          {modal && <>
            {data[selected].clientName}
          </>}

        </ModalHeader>
        <ModalBody>
        {modal && 
        <div className="row">
          <div className="col-12 text-center">
          <img className="rounded-circle d-inline" style={{ width: '100px' }} src={data[selected].clientPhoto} />
          </div>
          <div className="col-12 mt-4 text-center">
                            {/* <span style={{ marginLeft: "-20px", marginTop: "-10px", lineHeight: "normal" }} className="h1 mb-0 position-absolute">&ldquo; </span> */}
                            <div>
                              <p
                               

                              ><em>{data[selected].description}</em></p>
                            </div>
                            {/* <span style={{ marginLeft: "10px", marginTop: "10px", lineHeight: "normal" }} className="h1   position-absolute"> &rdquo;</span> */}
                          </div>
                          <div className="col-12 mt-4 text-center">
                            {data[selected].clientName} {data[selected].clientLocation !=='' && <>({data[selected].clientLocation})</>}
                          </div>
                          <div className="col-12 mt-2 text-center">
                            @{data[selected].plateform}
                          </div>
          </div>
          }
        </ModalBody>
      </Modal>
    </>
  );
}
export default Testimonial;
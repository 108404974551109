import React, { useEffect } from "react";
import Slider from "react-slick";
// reactstrap components
const Team = ({ data, itemSHow, itemSHowMD,itemSHowsm,itemSHowXS }) => {
  const settings = {
    dots: false,
    infinite: true,
    draggable: true,
    speed: 500,
    slidesToShow: itemSHow,
    slidesToScroll: 1,
    autoplay: true,
    centerPadding: '50px',
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: itemSHowMD,
          slidesToScroll: 1,
          initialSlide: 0
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: itemSHowsm,
          slidesToScroll: 1,
          initialSlide: 0
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: itemSHowXS,
          slidesToScroll: 1,
          initialSlide: 0
        }
      }
    ]
  };
  return (
    <div className="row ">
        <div className="col-12">          
            <Slider {...settings}>
              {data.map((item, index) => {
                return <div className="p-3" key={index}>
                  <div className="card h-100 bg-transparent border-0">
                    <div className="card-body p-0 text-center">
                      <div className="row">
                        <div className="col-12">
                          <img className=" d-inline teamroundedimg w-100" src={item.thumb} />
                        </div>

                        <div className="col-12 mt-4 h5 mb-0">
                          {item.name}
                        </div>
                        <div className="col-12  mb-0">
                          ({item.position})
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              })}
            </Slider>
          
        </div>
      

    </div>
  );
}
export default Team;
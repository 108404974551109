import React from "react";
import { Helmet } from "react-helmet-async";
import { Link, NavLink } from "react-router-dom";
// reactstrap components
import { Container, Row, Col } from "reactstrap";

// core components
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import SimpleFooter from "components/Footers/SimpleFooter.js";

// index page sections
import Catagories from "../components/CatagoriesSlide/Index.js";
import CategoriesProduct from "../components/CatagoriesSlide/CategoriesProduct.js";
import RecentWork from "../components/RecentWork/RecentWorkThumb.js";
import Testimonial from "../components/Testimonial/Index.js";
import Team from "../components/Team/Index.js";
import HomeSliderImage from "../components/HomeSlider/index.js";
import BestSellerProduct from "../components/BestSeller/BestSellerProduct.js";
import HomeStories from "../components/HomeStories/Index.js";
import Loader from "../components/Loader/Index.js"
class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      stories: [],
      teamlist: [],
      products: [],
      categories: [],
      recentWork: [],
      testimonial: [],
      banner: [],
      recentWOrkTab: [
        { name: 'Wedding Work', checked: true },
        { name: 'Reception Work', checked: false },
      ]
    }
    this.exportRef = React.createRef();
  }
  loadTeam = () => {
    fetch('./API/team.json', {
    })
      .then((response) => response.json())
      .then((responseJson) => {
        let tempData = responseJson;
        this.state.teamlist = tempData
        this.setState({ teamlist: this.state.teamlist })
      })
      .catch(error => {
        console.log(error);
      })
  }
  loadTestimonial = () => {
    fetch('./API/testimonial.json', {
    })
      .then((response) => response.json())
      .then((responseJson) => {
        let tempData = responseJson;
        this.state.testimonial = tempData
        this.setState({ testimonial: this.state.testimonial })
      })
      .catch(error => {
        console.log(error);
      })
  }
  loadRecentWork = () => {
    fetch('./API/recentWork.json', {
    })
      .then((response) => response.json())
      .then((responseJson) => {
        let tempData = responseJson;
        this.state.recentWork = tempData
        this.setState({ recentWork: this.state.recentWork })
      })
      .catch(error => {
        console.log(error);
      })
  }
  loadProduct = () => {
    fetch('./API/products.json', {
    })
      .then((response) => response.json())
      .then((responseJson) => {
        let tempData = responseJson;
        this.state.products = tempData
        this.setState({ products: this.state.products })
        this.setState({ loading: false })
      })
      .catch(error => {
        console.log(error);
      })
  }
  loadBanner = () => {
    fetch('./API/homebanner.json', {
    })
      .then((response) => response.json())
      .then((responseJson) => {
        let tempData = responseJson;
        this.state.banner = tempData
        this.setState({ banner: this.state.banner })
      })
      .catch(error => {
        console.log(error);
      })
  }
  loadCategories = () => {
    fetch('./API/categories.json', {
    })
      .then((response) => response.json())
      .then((responseJson) => {
        let tempData = responseJson;
        this.state.categories = tempData
        this.setState({ categories: this.state.categories })
      })
      .catch(error => {
        console.log(error);
      })
  }
  loadstories = () => {
    fetch('./API/stories.json', {
    })
      .then((response) => response.json())
      .then((responseJson) => {
        let tempData = responseJson;
        this.state.stories = tempData
        this.setState({ stories: this.state.stories })
      })
      .catch(error => {
        console.log(error);
      })
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    this.loadBanner()
    this.loadTeam()
    this.loadProduct()
    this.loadCategories()
    this.loadRecentWork()
    this.loadstories()
    this.loadTestimonial()

  }
  getFilterDataCategories = (name, param, val) => {
    console.log(this.state[name].filter((items) => items[param] === val))
    return this.state[name].filter((items) => items[param] === val)
  }
  getFilterDataRecentWork = (name, param, val) => {
    return this.state[name].filter((items) => items[param].some(cat => cat === val))
  }
  getFilterDataProduct = (name, param, val) => {
    return this.state[name].filter((items) => items[param].some(cat => cat === val))
  }
  changeTab = (ind) => {
    this.state.recentWOrkTab.forEach((item) => {
      item.checked = false
    })
    this.state.recentWOrkTab[ind].checked = true;

    this.setState({
      recentWOrkTab: this.state.recentWOrkTab
    })
  }
  render() {
    let ifProduct = false;
    let ifRecentWOrk = false;
    let ifcategories = false;
    let teamlist = false;
    let ifTestimonial = false;
    if (this.state.testimonial.length) {
      ifTestimonial = true
    }
    if (this.state.products.length) {
      ifProduct = true
    }
    if (this.state.categories.length) {
      ifcategories = true
    }
    if (this.state.recentWork.length) {
      ifRecentWOrk = true
    }
    if (this.state.teamlist.length) {
      teamlist = true
    }

    let ifStories = false;
    let storiesData = this.state.stories
    if (this.state.stories.length) {
      ifStories = true

    }
    let ifbanner = false;
    if (this.state.banner.length) {
      ifbanner = true
    }
    console.log(storiesData)
    return (
      <>
        <Helmet>
          <title>Bengali Wedding Card | Traditional Marriage Invitation Cards</title>
          <meta name="description"
            content="Buy bengali wedding card online from Porinoy. Explore our exclusive collection of traditional, madhubani, jamini roy and many more marriage invitation card." />
          <meta property="og:title" content="Bengali Wedding Card | Traditional Marriage Invitation Cards" />
          <meta property="og:description" content="Buy bengali wedding card online from Porinoy. Explore our exclusive collection of traditional, madhubani, jamini roy and many more marriage invitation card." />
        </Helmet>
        <DemoNavbar />
        <main ref="main">
          {ifbanner && <HomeSliderImage data={this.state.banner} itemSHow={1} />}
          <section style={{ padding: '1rem 0px', background: "#fad5ab" }} className="section ">
            <Container className="bestSellerContainer" fluid>
              <Row>
                <Col className="text-center">
                  <h1 className="home-h1 mb-0 mh1-fs text-danger">
                    Porinoy Bengali Wedding Cards - Reflect the <br className="d-none" /> Elegance and Beauty of Your Wedding
                  </h1>
                </Col></Row>
            </Container>
          </section>
          {/* <Carousel cusPadding="4rem  0px" /> */}

          <section className="section">
            <Container fluid>
              <Row>
                <Col>
                  <div style={{ paddingRight: '50px' }}>
                    <h3 style={{ fontWeight: "700" }} className="h2 ">
                      Best Seller
                    </h3>
                  </div>
                  {ifProduct && <BestSellerProduct itemSHow={4} itemSHowMD={2} itemSHowsm={2} itemSHowXS={1} data={this.getFilterDataProduct('products', "category", "2")} />}

                </Col>
                <Col className="col-12 text-center mt-4 ">
                  <div className="btn-expanded-middle-border position-relative">
                    <Link className="btn btn-danger rounded-pill text-uppercase" to="/products">
                      View All Cards <i class="fa-solid fa-arrow-right-long"></i>
                    </Link>

                  </div>

                </Col>
              </Row>

            </Container>
          </section>
          <section className="section pt-0">
            <Container fluid>
              <Row>
                <Col>
                  <div style={{ paddingRight: '50px' }}>
                    <h3 style={{ fontWeight: "700" }} className="h2">
                      New Collection
                    </h3>
                  </div>
                  {ifProduct && <BestSellerProduct itemSHow={4} itemSHowMD={2} itemSHowsm={2} itemSHowXS={1} data={this.getFilterDataProduct('products', "category", "1")} />}

                </Col>
                <Col className="col-12 text-center mt-4 ">
                  <div className="btn-expanded-middle-border position-relative">
                    <Link className="btn btn-danger rounded-pill text-uppercase" to="/products">
                      View All Cards <i class="fa-solid fa-arrow-right-long"></i>
                    </Link>

                  </div>

                </Col>
              </Row>

            </Container>
          </section>
          <section className="section mb-0 mb-lg-0 mb-xl-0 mb-md-0 pt-0">
            <Container fluid>
              <Row>
                <Col style={{ marginBottom: "2rem" }} className="col col-lg-6  col-xl-6">
                  <div style={{ paddingRight: '50px' }}>
                    <h2 className="h3">
                      <span style={{ fontWeight: "600" }}>Madhubani Wedding Card</span>
                    </h2>
                  </div>
                  {ifcategories && <Catagories
                    itemSHow={2}
                    itemSHowMD={2} itemSHowsm={2} itemSHowXS={1}
                    data={this.getFilterDataProduct('products', 'category', '3')}
                  />}
                  <div className="mt-3">
                    <Link className="btn btn-danger rounded-pill text-uppercase" to="/product-category/3">
                      Explore Now<i class="fa-solid fa-arrow-right-long"></i>
                    </Link>
                  </div>
                </Col>
                <Col style={{ marginBottom: "2rem" }} className="col col-lg-6  col-xl-6 ">
                  <div style={{ paddingRight: '50px' }}>
                    <h2 className="h3">
                      <span style={{ fontWeight: "600" }}>Premium Wedding Card</span>
                    </h2>
                  </div>
                  {ifcategories && <Catagories
                    itemSHow={2}
                    itemSHowMD={2} itemSHowsm={2} itemSHowXS={1}
                    data={this.getFilterDataProduct('products', 'category', '4')}
                  />}
                  <div className="mt-3">
                    <Link className="btn btn-danger rounded-pill text-uppercase" to="/product-category/4">
                      Explore Now<i class="fa-solid fa-arrow-right-long"></i>
                    </Link>

                  </div>
                </Col>
                <Col style={{ marginBottom: "2rem" }} className="col col-lg-6  col-xl-6">
                  <div style={{ paddingRight: '50px' }}>
                    <h2 className="h3">
                      <span style={{ fontWeight: "600" }}>Traditional Wedding Card</span>
                    </h2>
                  </div>
                  {ifcategories && <Catagories
                    itemSHow={2}
                    itemSHowMD={2} itemSHowsm={2} itemSHowXS={1}
                    data={this.getFilterDataProduct('products', 'category', '5')}
                  />}
                  <div className="mt-3">
                    <Link className="btn btn-danger rounded-pill text-uppercase" to="/product-category/5">
                      Explore Now<i class="fa-solid fa-arrow-right-long"></i>
                    </Link>
                  </div>
                </Col>
                <Col style={{ marginBottom: "2rem" }} className="col col-lg-6  col-xl-6 ">
                  <div style={{ paddingRight: '50px' }}>
                    <h2 className="h3">
                      <span style={{ fontWeight: "600" }}>Laser Cutting Wedding Card</span>
                    </h2>
                  </div>
                  {ifcategories && <Catagories
                    itemSHow={2}
                    itemSHowMD={2} itemSHowsm={2} itemSHowXS={1}
                    data={this.getFilterDataProduct('products', 'category', '6')}
                  />}
                  <div className="mt-3">
                    <Link className="btn btn-danger rounded-pill text-uppercase" to="/product-category/6">
                      Explore Now<i class="fa-solid fa-arrow-right-long"></i>
                    </Link>

                  </div>
                </Col>
                {/* <Col>
                  <div className="h3">
                    New Arrivals
                  </div>
                  {ifcategories && <Catagories itemSHow={1} data={this.getFilterDataCategories('categories', 'name', 'new')} />}
                </Col> */}
              </Row>

            </Container>
          </section>
          <section style={{ paddingBottom: "0px" }} className="section text-center mt-0 mt-lg-5 mt-xl-5 mt-md-5 mb-5 position-relative">
            <img style={{ left: '-10px', top: "50%", zIndex: '9', marginTop: "-245px" }} className="position-static-mobile position-absolute d-none d-lg-block d-xl-block"
              src="images/bou.png" />
            <Container className="d-block d-lg-none d-xl-none" fluid>
              <Row className="align-items-center">
                <Col className="text-left col-6">
                  <img
                    style={{ maxWidth: "100%", marginBottom: "-3rem" }}
                    className=""
                    src="images/bou.png"
                  />
                </Col>
                <Col className="text-left col-6">
                  <img
                    style={{ maxWidth: "100%", marginBottom: "-2rem" }}
                    className=""
                    src="images/bor.png"
                  />
                </Col>
              </Row>
            </Container>
            <div className="position-relative" style={{ backgroundImage: "url(images/loveusbg.png)", paddingTop: "4rem", paddingBottom: "2rem" }}>
              <Container className="bestSellerContainer" fluid>
                <img style={{ left: '190px', top: "0px", height: "100%" }} className="position-static-mobile position-absolute d-none d-lg-block d-xl-block" src="images/reason-love-left-bg.png" />
                <Row>
                  <Col className="text-center col-12">
                    <div className="h3 mb-4 text-white">
                      Great Reasons To Love us
                    </div>
                  </Col>
                </Row>
                <Container className="bestSellerContainer" fluid>
                  <Row className="justify-content-center">
                    <Col style={{ marginBottom: "2rem" }} className="text-center text-white col-12 col-lg-3 col-md-3 col-xl-3">
                      <div className="mb-2"><i class="fa-solid fa-hand-holding-heart fa-2xl"></i></div>
                      <div >Trust of five Years</div>
                    </Col>
                    <Col style={{ marginBottom: "2rem" }} className="text-center text-white col-12 col-lg-3 col-md-3 col-xl-3">
                      <div className="mb-2"><i class="fa-regular fa-face-smile  fa-2xl"></i></div>
                      <div>1000+ Happy Customers</div>
                    </Col>
                    <Col style={{ marginBottom: "2rem" }} className="text-center text-white col-12 col-lg-3 col-md-3 col-xl-3">
                      <div className="mb-2"><i class="fa-regular fa-clock  fa-2xl"></i></div>
                      <div>First Production Time</div>
                    </Col>
                    <Col style={{ marginBottom: "2rem" }} className="text-center text-white col-12 col-lg-3 col-md-3 col-xl-3">
                      <div className="mb-2"><i class="fa-regular fa-credit-card  fa-2xl"></i></div>
                      <div>Secure Payments</div>
                    </Col>
                  </Row>
                </Container>
                <img style={{ right: '190px', top: "0px", height: "100%" }} className="position-static-mobile position-absolute d-none d-lg-block d-xl-block" src="images/reason-love-right-bg.png" />
              </Container>

            </div>
            <img className="position-absolute mala" src="images/mala.png" />
            <img style={{ right: '-10px', top: "50%", zIndex: '9', marginTop: '-227px' }} className="position-static-mobile position-absolute d-none d-lg-block d-xl-block" src="images/bor.png" />
          </section>
          <section className="section">
            <Container fluid>
              <Row>
                <Col className="text-center col-12">
                  <h3 style={{ fontWeight: "700" }} className="h2 mb-4">
                    Our Recent Work
                  </h3>
                  <div className="mb-3">
                    {this.state.recentWOrkTab.map((item, ind) => {
                      let active = '';
                      if (item.checked) {
                        active = 'active'
                      }
                      return <button onClick={() => this.changeTab(ind)} key={ind} className={"text-uppercase rc-tab-button " + active}>
                        {item.name}
                      </button>
                    })}
                  </div>
                  <div>
                    {this.state.recentWOrkTab.map((item, ind) => {
                      if (item.checked) {
                        return <div key={ind} >
                          {ind === 1 && <div>{ifRecentWOrk && <RecentWork itemSHow={3} itemSHowMD={2} itemSHowsm={2} itemSHowXS={1} data={this.getFilterDataRecentWork('recentWork', 'category', "reception")} />}</div>}
                          {ind === 0 && <div>{ifRecentWOrk && <RecentWork itemSHow={3} itemSHowMD={2} itemSHowsm={2} itemSHowXS={1} data={this.getFilterDataRecentWork('recentWork', 'category', "wedding")} />}</div>}
                        </div>
                      }
                    })}
                  </div>


                </Col>

              </Row>

            </Container>
          </section>
          <section style={{ paddingBottom: "2rem", background: 'url(images/team-bg.jpg)', backgroundSize: 'cover' }} className="section border-bottom">
            <Container fluid>
              <div className="row align-items-center productFeatures">
                <div className="col-6 productFeatures-item col-lg-4 col-xl-3 col-md-6">
                  <div className="row align-items-center">
                    <div className="col-12 col-lg-auto col-md-auto col-xl-auto">
                      <div
                        style={{
                          backgroundImage: "url(images/icons-sprite.png)",
                          backgroundRepeat: "no-repeat",
                          backgroundPosition: "center 14px",
                          backgroundPosition: "center -152px",
                          margin: "0 auto"
                        }}
                        className="fItem active mb-2 mb-lg-0 mb-xl-0 mb-md-0"
                      ></div>
                    </div>
                    <div className="col-12 col-lg col-md col-xl text-center text-lg-left text-xl-left text-md-left">
                      <div>Unique & Exclusive
                      </div>
                      <div>Wedding Invitation Cards </div>
                    </div>
                  </div>
                </div>
                <div className="col-6 productFeatures-item col-lg-4 col-xl-3 col-md-6">
                  <div className="row align-items-center">
                    <div className="col-12 col-lg-auto col-md-auto col-xl-auto">
                      <div
                        style={{
                          backgroundImage: "url(images/icons-sprite.png)",
                          backgroundRepeat: "no-repeat",
                          backgroundPosition: "center -238px",
                          margin: "0 auto"
                        }}
                        className="fItem  mb-2 mb-lg-0 mb-xl-0 mb-md-0"
                      ></div>
                    </div>
                    <div className="col-12 col-lg col-md col-xl text-center text-lg-left text-xl-left text-md-left">
                      <div>Unlimited   Design</div>
                      <div> Drafts</div>
                    </div>
                  </div>
                </div>
                <div className="col-6 productFeatures-item col-lg-4 col-xl-3 col-md-6">
                  <div className="row align-items-center">
                    <div className="col-12 col-lg-auto col-md-auto col-xl-auto">
                      <div
                        style={{
                          backgroundImage: "url(images/icons-sprite.png)",
                          backgroundRepeat: "no-repeat",

                          backgroundPosition: "center -71px",
                          margin: "0 auto"
                        }}
                        className="fItem  mb-2 mb-lg-0 mb-xl-0 mb-md-0"
                      ></div>
                    </div>
                    <div className="col-12 col-lg col-md col-xl text-center text-lg-left text-xl-left text-md-left">
                      <div>Online Payment

                      </div>
                      <div>All Over India Delivery</div>
                    </div>
                  </div>
                </div>
                <div className="col-6 productFeatures-item col-lg-4 col-xl-3 col-md-6">
                  <div className="row align-items-center">
                    <div className="col-12 col-lg-auto col-md-auto col-xl-auto">
                      <div
                        style={{
                          backgroundImage: "url(images/delivery-man.png)",
                          backgroundRepeat: "no-repeat",
                          backgroundPosition: "center 14px",
                          margin: "0 auto"
                        }}
                        className="fItem  mb-2 mb-lg-0 mb-xl-0 mb-md-0"
                      ></div>
                    </div>
                    <div className="col-12 col-lg col-md col-xl text-center text-lg-left text-xl-left text-md-left">
                      <div>Delivery
                      </div>
                      <div>Within 15 Days</div>
                    </div>
                  </div>
                </div>

              </div>
            </Container>
          </section>

          <section className="section">
            <Container className="bestSellerContainer " fluid>
              <Row>
                <Col className="text-center col-12">
                  <div className="h6 mb-0 text-uppercase">
                    Testimonials
                  </div>
                </Col>
                <Col className="text-center col-12">
                  <h3 style={{ fontWeight: "700" }} className="h1 text-uppercase">
                    What Our Customers Says
                  </h3>
                </Col>
                <Col className="col-12">
                  {ifTestimonial &&
                    <Testimonial itemSHow={3} itemSHowMD={2} itemSHowsm={1} itemSHowXS={1} data={this.state.testimonial} />
                  }

                </Col>
              </Row>

            </Container>
          </section>

          <section className="section ">
            <Container className="bestSellerContainer " fluid>
              {ifStories && <HomeStories data={storiesData} />}
            </Container>
          </section>
          <section style={{ paddingBottom: '1.5rem' }} className="section">
            <Container fluid>
              <Row className="m-0">
                <Col className="text-center col-12">
                  <h3 style={{ fontWeight: "700" }} className="h2 mb-5">
                    How it Works
                  </h3>

                </Col>
              </Row>
              <Row className="m-0">
                <Col className="p-0 col-12 col-lg-4 col-xl col-md-6 col-sm-6 hworks">
                  <div

                    data-number="01"
                    className="howWorksItem text-center active"
                  >
                    <div
                      style={{
                        backgroundImage: "url(images/icons-sprite.png)",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center -321px"
                      }}
                      className="howWorksItem-icon">

                    </div>
                    <div>Select</div>
                    <div>Your Cards</div>
                  </div>
                </Col>
                <Col className="p-0 col-12 col-lg-4 col-xl col-md-6 col-sm-6 hworks">
                  <div
                    data-number="02"
                    className="howWorksItem text-center"
                  >
                    <div
                      style={{
                        backgroundImage: "url(images/icons-sprite.png)",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center -396px"
                      }}
                      className="howWorksItem-icon">

                    </div>
                    <div>Fill Up Bride Groom   </div>
                    <div>Details Form</div>

                  </div>
                </Col>
                <Col className="p-0 col-12 col-lg-4 col-xl col-md-6 col-sm-6 hworks">
                  <div
                    data-number="03"
                    className="howWorksItem text-center"
                  >
                    <div
                      style={{
                        backgroundImage: "url(images/icons-sprite.png)",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center -468px"
                      }}
                      className="howWorksItem-icon">

                    </div>
                    <div>Get Invitation Matter  </div>
                    <div>through WhatsApp</div>
                  </div>
                </Col>
                <Col className="p-0 col-12 col-lg-4 col-xl col-md-6 col-sm-6 hworks">
                  <div
                    data-number="04"
                    className="howWorksItem text-center"
                  >
                    <div
                      style={{
                        backgroundImage: "url(images/icons-sprite.png)",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center -545px"
                      }}
                      className="howWorksItem-icon">

                    </div>
                    <div>Review/Finalize </div>
                    <div>Your Proofs</div>
                  </div>
                </Col>
                <Col className="p-0 col-12 col-lg-4 col-xl col-md-6 col-sm-6 hworks">
                  <div
                    data-number="05"
                    className="howWorksItem text-center"
                  >
                    <div
                      style={{
                        backgroundImage: "url(images/icons-sprite.png)",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center -610px"
                      }}
                      className="howWorksItem-icon">

                    </div>
                    <div>Secure & Succesfull</div>
                    <div>Deliver Process</div>
                  </div>
                </Col>
              </Row>

            </Container>
          </section>
          <section style={{ background: 'url(images/team-bg.jpg)', backgroundSize: 'cover' }} className="section ">
            <Container className="bestSellerContainer " fluid>
              <Row>
                <Col className="text-center">
                  <h3 style={{ fontWeight: "700" }} className="h1 text-uppercase">
                    Meet Our Team
                  </h3>
                  {teamlist && <Team data={this.state.teamlist} itemSHow={4} itemSHowMD={3} itemSHowsm={2} itemSHowXS={1} />}

                </Col>

              </Row>

            </Container>
          </section>
          {/* <Buttons />
          <Inputs />
          <section className="section">
            <Container>
              <CustomControls />
              <Menus />
            </Container>
          </section>
          <Navbars />
          <section className="section section-components">
            <Container>
              <Tabs />
              <Row className="row-grid justify-content-between align-items-center mt-lg">
                <Progress />
                <Pagination />
              </Row>
              <Row className="row-grid justify-content-between">
                <Pills />
                <Labels />
              </Row>
              <Alerts />
              <Typography />
              <Modals />
              <Datepicker />
              <TooltipPopover />
            </Container>
          </section>
          <Carousel />
          <Icons />
          <Login />
          <Download /> */}
        </main>
        <SimpleFooter />
        {this.state.loading && <Loader />}
      </>
    );
  }
}

export default Home;

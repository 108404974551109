
import React from "react";
import { Helmet } from "react-helmet-async";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardImg,
  FormGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
  Modal, ModalHeader, ModalBody, ModalFooter
} from "reactstrap";

// core components
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import SimpleFooter from "components/Footers/SimpleFooter.js";

class Contact extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      phone: "",
      pincode: "",
      wdate: "",
      enquiry: ""

    }

  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }
  onchange = (e) => {
    let name = e.target.name;
    let val = e.target.value;
    this.setState({
      [name]: val
    })
  }
  ValidateMobileNumber(mobileNumber) {
    var lblError = document.getElementById("lblError");
    var expr = /^(0|91)?[6-9][0-9]{9}$/;
    if (!expr.test(mobileNumber)) {
      return false
    }
    return true
  }
  sendEnquery = () => {
    let name = this.state.name;
    let phone = this.state.phone;
    let enq = this.state.enquiry;
    let pincode = this.state.pincode;
    let wdate = this.state.wdate;
    if (name == '' || phone == '' || enq == '' || pincode == '' || wdate == '') {
      toast.error("Please fill all the required field")
    } else {
      if (!this.ValidateMobileNumber(phone)) {
        toast.error("Please fill a valid phone number")
      } else {
        let url = 'https://wa.me/+919832888069?text='
        let message = "Contact from https://porinoy.in %0a %0a"
        message = message + "Name %0a";
        message = message + name + "%0a %0a";
        message = message + "Phone Number %0a";
        message = message + phone + "%0a %0a";
        message = message + "Pincode Number %0a";
        message = message + pincode + "%0a %0a";
        message = message + "Wedding/Reception Date  %0a";
        message = message + wdate + "%0a %0a";
        message = message + "Message %0a";
        message = message + enq + "%0a %0a";
        url = url + message
        window.open(url)
      }

    }
  }
  render() {
    return (
      <>
        <Helmet>
          <title>Indian Wedding Cards | Contact Us</title>
          <meta name="description"
            content="Contact to Porinoy . WhatsApp +91-9832888069" />
          <meta property="og:title" content='Indian Wedding Cards | Contact Us' />
          <meta property="og:description" content='Contact to Porinoy . WhatsApp +91-9832888069' />
        </Helmet>
        <DemoNavbar />
        <main ref="main">
          <section
            style={{ backgroundImage: "url(images/inner-banner-bg.webp)", backgroundPosition: "center", backgroundSize: "cover" }}
            className="section pageTitleBG">
            <div className="row m-0">
              <div className="col-12">
                <h3 style={{ fontWeight: "500" }} className="h1 text-center text-uppercase mb-0">Contact Us</h3>
              </div>
            </div>
          </section>
          <section className="section border-top">
            <Container fluid>
              <div className="row">
                <div className="col-12 col-xl-6 col-lg-6 mb-4 mb-lg-0 mb-xl-0">
                  <div className="contact100-more">
                    <div className="row h-100 align-items-center justify-content-center">
                      <div className="col-12 col-xl-8 col-lg-8">
                        <div className="row mb-3 justify-content-center">
                          <div className="col-auto ">
                            <span style={{ width: "30px" }} className="text-white h4 d-inline-block"><i class="fa-solid fa-location-dot"></i></span>
                          </div>
                          <div className="col">
                            <div className="h4 text-white">Address</div>
                            <div className="text-white">
                              Ashoknagar, North 24 Parganas, West Bengal, India
                            </div>
                          </div>
                        </div>
                        <div className="row  mb-3 justify-content-center">
                          <div className="col-auto">
                            <span style={{ width: "30px" }} className="text-white h4  d-inline-block"><i class="fa-solid fa-phone-volume"></i></span>
                          </div>
                          <div className="col">
                            <div className="h4 text-white">Lets Talk</div>
                            <div className="text-white">
                              +91-9832888069
                            </div>
                          </div>
                        </div>
                        <div className="row justify-content-center">
                          <div className="col-auto">
                            <span style={{ width: "30px" }} className="text-white h4  d-inline-block"><i class="fa-solid fa-envelope"></i></span>
                          </div>
                          <div className="col">
                            <div className="h4 text-white">Mail Us</div>
                            <div className="text-white">
                              <a style={{ color: "#fff" }} href="mailto:info@porinoy.in">info@porinoy.in</a>
                              <div>
                                <a style={{ color: "#fff" }} href="mailto:sales@porinoy.in">sales@porinoy.in</a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>


                  </div>
                </div>
                <div className="col-12 col-xl-6 col-lg-6">
                  <div className="row">
                    <div className="col-12 col-lg-6 col-xl-6 ">
                      <div class="form-floating mb-3">
                        <input onChange={(e) => this.onchange(e)} value={this.state.name} name="name" type="text" class="form-control" id="floating-name" placeholder="Your Name" />
                        <label htmlFor="floating-name">Your Name <span className="text-danger">*</span></label>
                      </div>
                    </div>

                    <div className="col-12 col-lg-6 col-xl-6 ">
                      <div class="form-floating mb-3">
                        <input onChange={(e) => this.onchange(e)} value={this.state.phone} name="phone" type="number" class="form-control" id="floating-phone" placeholder="Your phone number" />
                        <label htmlFor="floating-phone">Your phone number <span className="text-danger">*</span></label>
                      </div>
                    </div>
                    <div className="col-12 col-lg-6 col-xl-6 ">
                      <div class="form-floating mb-3">
                        <input onChange={(e) => this.onchange(e)} value={this.state.pincode} name="pincode" type="number" class="form-control" id="floating-pincode" placeholder="Your Pincode" />
                        <label htmlFor="floating-pincode">Your Pincode <span className="text-danger">*</span></label>
                      </div>
                    </div>
                    <div className="col-12 col-lg-6 col-xl-6 ">
                      <div class="form-floating mb-3">
                        <input onChange={(e) => this.onchange(e)} value={this.state.wdate} name="wdate" type="date" class="form-control" id="floating-wdate" placeholder="Your Wedding/Reception Date" />
                        <label htmlFor="floating-wdate">Wedding/Reception Date <span className="text-danger">*</span></label>
                      </div>
                    </div>
                    <div className="col-12">
                      <div class="form-floating mb-3">
                        <textarea onChange={(e) => this.onchange(e)} value={this.state.enquiry} name="enquiry" style={{ height: "auto" }} rows={5} type="text" class="form-control" id="floating-message" placeholder="Your Message" />
                        <label htmlFor="floating-message">Your Message <span className="text-danger">*</span></label>
                      </div>
                    </div>
                    <div className="col-12">
                      <div class="form-floating ">
                        <button onClick={() => this.sendEnquery()} className="btn btn-danger btn-lg">Submit</button>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </Container>
          </section>
          <section className='section py-0'>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3676.601548486104!2d88.6281466!3d22.854226600000004!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39f8bb5c821b782d%3A0xb5af9508e795e2e7!2sPorinoy%20-%20Bengali%20Wedding%20Card%20Shop!5e0!3m2!1sen!2sin!4v1695555315396!5m2!1sen!2sin"
              width="600"
              height="400"
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
              className="w-100 border-0"
            ></iframe>
          </section>
        </main >
        <SimpleFooter />

      </>
    );
  }
}

export default Contact;

import React from "react";
import Slider, { Range } from 'rc-slider';
import 'rc-slider/assets/index.css';
function PriceFilter({ data, applyFilterPrice }) {
    const onSliderChange = (value) => {
        let datas = data;
        datas.selected = value
        applyFilterPrice(datas)
    };
    return (
        <>
            <div>
                <div className="h5">
                    Select Price Range
                </div>
                <div>
                    <Slider 
                    range  
                    allowCross={false}
                    onChange={onSliderChange} 
                    defaultValue={data.selected} 
                    min={data.min} 
                    max={data.max}
                     />
                </div>
                <div className="text-end">
                    Price {data.selected[0]} - {data.selected[1]}
                </div>
            </div>
        </>
    )

}
export default PriceFilter;
import React, { useEffect } from "react";
import { useNavigate } from 'react-router-dom'
// reactstrap components
import Slider from "react-slick";
import ReactHtmlParser from 'react-html-parser';
const HomeStoriesLoop = ({ data }) => {
  let navigate = useNavigate();
  const goToDetails = (id) => {
    navigate('/stories-details/' + id)
  }
  const settings = {
    dots: false,
    infinite: true,
    draggable: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    centerPadding: '50px',

  };
  return (
    <div className="col-12 mt-3 mt-lg-0 mt-xl-0 mt-md-0">
    <div className="row">
      <div className="col-12 h-100">
        <div className="testimonialslide mx-5">
          <Slider {...settings}>
            {data.map((item, index) => {
              let ifLocation = false;
              if (item.clientLocation !== '') {
                ifLocation = true
              }
              return <div className="h-100" key={index}>
                <div className="row">
                <div className="col-12 ">
                  <div style={{width:'130px',height:"130px",overflow:"hidden", borderRadius:"50%", margin:"0 auto"}}>
                  <img style={{width:"100%", height:"100%",objectFit:"cover"}} src={item.image} />
                  </div>
                  
                </div>
                  <div className="col-12 ">
                    <h5 className="h5">{item.title} </h5>
                  </div>
                  <div className="col-12 mt-2 ">
                    {item.date} <span style={{ margin: "0px 10px" }}>|</span> {item.author}
                  </div>
                  <div className="col-12 mt-2">
                    <div 
                    style={{
                      overflow:"hidden",
                      textOverflow:"ellipsis",
                      width:"100%",
                      height:"110px"
                    }}
                    >
                      {ReactHtmlParser(item.description)}
                    </div>
                  </div>
                  <div className="col-12 mt-2">
                    <button onClick={() => goToDetails(item.id)} className="btn btn-danger rounded-pill text-uppercase">
                      View Details
                    </button>
                  </div>
                </div>

              </div>
            })}
          </Slider>
        </div>
      </div>

    </div>
    </div>
  );
}
export default HomeStoriesLoop;
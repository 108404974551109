import React, { useEffect } from "react";
import {isMobile, isIPad13, isTablet} from 'react-device-detect';
// reactstrap components
import Slider from "react-slick";
import NextArrow from "../SliderNav/NextNav";
import PrevArrow from "../SliderNav/PrevNav";
const HomeSliderImage = ({ data, itemSHow }) => {
  let items = data;
  const settings = {
    dots: true,
    pauseOnHover: true,
    infinite: true,
    draggable: true,
    speed: 500,
    slidesToShow: itemSHow,
    slidesToScroll: 1,
    autoplay: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };
  return (
    <div className="row m-0 homemainslide">
      <div className="col-12 p-0">
        <div className="d-none d-lg-block d-xl-block d-md-block">
          <Slider {...settings}>
            {items.map((item, index) => {
              return <div key={index}>
                <img width="100%" src={item.lg} />
              </div>
            })}
          </Slider>
        </div>
        <div className="d-none d-lg-none d-xl-none d-md-none d-sm-block">
          <Slider {...settings}>
            {items.map((item, index) => {
              if(item.tab){
                return <div key={index}>
                <img width="100%" src={item.tab} />
              </div>
              }
             
            })}
          </Slider>
        </div>
        <div className="d-block d-lg-none d-xl-none d-md-none d-sm-none">
          <Slider {...settings}>
          {items.map((item, index) => {
              if(item.mob){
                return <div key={index}>
                <img style={{width:"100%"}} src={item.mob} />
              </div>
              }
            })}
          </Slider>
        </div>
      </div>

    </div>
  );
}
export default HomeSliderImage;
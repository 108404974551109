import React from "react";
// reactstrap components
import HomeStoriesLoop from "./HomeStoriesLoop"
const HomeStories = ({ data }) => {

  return (
    <>
    <div className="row align-items-center text-center d-block d-xl-none d-lg-none">
    <div className="col-12 ">
          <h4 className="h5 mb-0 text-danger text-uppercase">
            View Our Stories
          </h4>
        
        </div>
    </div>
    <div className="w-100-m h-100 w-50 position-static-m position-absolute left-0 top-0">
    <img className="position-static-m position-absolute left-0 top-0" style={{maxWidth:"100%",width:'100%', height:"100%",objectFit:"cover"}} alt="" src='images/stories.webp' />
    </div>
   
      <div className="row align-items-center text-center">
       
        <div className="col-12 col-lg-6 col-xl-6">

        </div>
        <div className="col-12 col-lg-6 col-xl-6">
          <div className="row mt-3 mt-lg-0 mt-xl-0 mt-md-3">
            <div className="col-12 d-none d-xl-block d-lg-block">
            <h4 className="h5 mb-0 text-danger text-uppercase">
            View Our Stories
          </h4>
            
            </div>
            <HomeStoriesLoop data={data} />

          </div>
        </div>

      </div>
    </>

  );
}
export default HomeStories;
import React from "react";
// reactstrap components
import { Link, NavLink as CNavLink } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  Button,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  Modal, ModalHeader, ModalBody, ModalFooter
} from "reactstrap";

class SimpleFooter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      enquiryModal: false,
      showTopBtn: false,
      name: "",
      phone: "",
      pincode: "",
      wdate: "",
      enquiry: ""
    }
  }
  componentDidMount() {
    window.addEventListener('scroll', () => {
      if (window.scrollY > 400) {
        this.setState({
          showTopBtn: true
        })
      } else {
        this.setState({
          showTopBtn: false
        })
      }
    });
  }
  goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };
  toggle = () => {
    this.setState({
      enquiryModal: !this.state.enquiryModal
    })
  }
  openEnquiry = () => {
    this.setState({
      enquiryModal: true
    })
  }
  onchange = (e) => {
    let name = e.target.name;
    let val = e.target.value;
    this.setState({
      [name]: val
    })
  }
  ValidateMobileNumber(mobileNumber) {
    var lblError = document.getElementById("lblError");
    var expr = /^(0|91)?[6-9][0-9]{9}$/;
    if (!expr.test(mobileNumber)) {
      return false
    }
    return true
  }
  sendEnquery = () => {
    let name = this.state.name;
    let phone = this.state.phone;
    let enq = this.state.enquiry;
    let pincode = this.state.pincode;
    let wdate = this.state.wdate;
    if (name == '' || phone == '' || enq == '' || pincode == '' || wdate == '') {
      toast.error("Please fill all the required field")
    } else {
      if (!this.ValidateMobileNumber(phone)) {
        toast.error("Please fill a valid phone number")
      } else {
        let url = 'https://wa.me/+919832888069?text='
        let message = "Contact from https://porinoy.in %0a %0a"
        message = message + "Name %0a";
        message = message + name + "%0a %0a";
        message = message + "Phone Number %0a";
        message = message + phone + "%0a %0a";
        message = message + "Pincode Number %0a";
        message = message + pincode + "%0a %0a";
        message = message + "Wedding/Reception Date  %0a";
        message = message + wdate + "%0a %0a";
        message = message + "Message %0a";
        message = message + enq + "%0a %0a";
        url = url + message
        window.open(url)
      }

    }
  }

  render() {
    return (
      <>
        <ToastContainer />
        <footer style={{ backgroundImage: "url(images/footer-bg.png)", backgroundSize: "cover", backgroundPosition: "bottom" }} className=" footer">
          <Container>
            <Row className="  align-items-center ">
              <Col lg="12">
                <div className="h4 text-center text-white text-uppercase">Contact Us</div>
              </Col>
              <Col lg="12">
                <div className="h1 ff-great-vibes mb-3 mb-lg-5 mb-xl-5 mb-md-5 text-center text-white footer-vibes-fon-size">Ways To Contact us</div>
              </Col>
              <Col className="text-lg-center btn-wrapper" lg="12">
                <div className="row">
                  <div className="col">
                    <div className="footerMedia">
                      <Link
                        className="footerIcon"
                        to="mailto:info@porinoy.in"
                      >
                        <i class="fa-regular fa-envelope"></i>
                      </Link>

                    </div>
                  </div>
                  <div className="col">
                    <div className="footerMedia">
                      <Link
                        className="footerIcon"
                        to="tel:9832888069"
                      >
                        <i class="fa-solid fa-phone-volume"></i>
                      </Link>
                    </div>
                  </div>
                  <div className="col">
                    <div className="footerMedia">
                      <Link
                        className="footerIcon"
                        to="https://wa.me/+919832888069?text="
                        target="_blank"
                      >
                        <i class="fa fa-whatsapp"></i>
                      </Link>
                    </div>
                  </div>
                  <div className="col">
                    <div className="footerMedia">
                      <Link
                        className="footerIcon"
                        to="https://www.facebook.com/porinoycard"
                        target="_blank"
                      >
                        <i class="fa fa-facebook"></i>
                      </Link>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>


          </Container>
          <Container className="mt-5">

            <Row className=" align-items-center justify-content-md-between ">
              <Col md="6">
                <Nav className=" nav-footer justify-content-center d-flex justify-content-lg-start  justify-content-xl-start justify-content-md-start">
                  <NavItem>
                    <NavLink
                      href="/privacy-policy"
                    >
                      Privacy Policy
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      href="/terms-condition"
                    >
                      Terms & Condition
                    </NavLink>
                  </NavItem>

                </Nav>
              </Col>
              <Col md="6">
                <div className=" copyright mt-3 mt-lg-0 mt-xl-0 mt-md-0 text-white justify-content-center d-flex justify-content-lg-end  justify-content-xl-end justify-content-md-end">
                  © {new Date().getFullYear()}  All Right Reserved.
                </div>

              </Col>
            </Row>
            <div className="floatingButtons">
              <ul>
                <NavItem>
                  <CNavLink className="whatsappLink" target="_blank" to="https://wa.me/+919832888069">
                    <i class="fa-brands text-success fa-whatsapp"></i>
                  </CNavLink>
                </NavItem>
                <NavItem>
                  <span onClick={() => this.openEnquiry()} className="d-block cursor-pointer">
                    <i class="fa-solid text-danger fa-comment"></i>
                  </span>

                </NavItem>
              </ul>
            </div>
          </Container>
        </footer>
        <Modal centered size="lg" isOpen={this.state.enquiryModal} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle}>Enquiry</ModalHeader>
          <ModalBody>
            <div className="row">
              <div className="col-6">
                <div class="form-floating mb-3">
                  <input onChange={(e) => this.onchange(e)} value={this.state.name} name="name" type="text" class="form-control" id="floating-name" placeholder="Your Name" />
                  <label htmlFor="floating-name">Your Name <span className="text-danger">*</span></label>
                </div>
              </div>

              <div className="col-6">
                <div class="form-floating mb-3">
                  <input onChange={(e) => this.onchange(e)} value={this.state.phone} name="phone" type="number" class="form-control" id="floating-phone" placeholder="Your phone number" />
                  <label htmlFor="floating-phone">Your phone number <span className="text-danger">*</span></label>
                </div>
              </div>
              <div className="col-6">
                <div class="form-floating mb-3">
                  <input onChange={(e) => this.onchange(e)} value={this.state.pincode} name="pincode" type="number" class="form-control" id="floating-pincode" placeholder="Your Pincode" />
                  <label htmlFor="floating-pincode">Your Pincode <span className="text-danger">*</span></label>
                </div>
              </div>
              <div className="col-6">
                <div class="form-floating mb-3">
                  <input onChange={(e) => this.onchange(e)} value={this.state.wdate} name="wdate" type="date" class="form-control" id="floating-wdate" placeholder="Your Wedding/Reception Date" />
                  <label htmlFor="floating-wdate">Wedding/Reception Date <span className="text-danger">*</span></label>
                </div>
              </div>
              <div className="col-12">
                <div class="form-floating">
                  <textarea onChange={(e) => this.onchange(e)} value={this.state.enquiry} name="enquiry" style={{ height: "auto" }} rows={5} type="text" class="form-control" id="floating-message" placeholder="Your Message" />
                  <label htmlFor="floating-message">Your Message <span className="text-danger">*</span></label>
                </div>
              </div>
            </div>

          </ModalBody>
          <ModalFooter>
            <button onClick={() => this.sendEnquery()} className="btn btn-danger">Submit</button>
          </ModalFooter>
        </Modal>
       {this.state.showTopBtn && <div  onClick={()=>this.goToTop()} className="top-to-btm ">
          <i className="fa-solid fa-arrow-up icon-style shadow"></i>
        </div>
        } 
      </>

    );
  }
}

export default SimpleFooter;


import React from "react";
import { Helmet } from "react-helmet-async";
import { copy } from 'copy-anything'
import {
  Button,
  Container,
  Modal, ModalHeader, ModalBody, ModalFooter
} from "reactstrap";

// core components
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import SimpleFooter from "components/Footers/SimpleFooter.js";
import ProductLoop from "components/Products/ProductLoop.js"
import CategoryFilter from "components/Filter/CategoryFilter.js"
import PriceFilter from "components/Filter/PriceFilter.js"
class Products extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showPerPage: 10,
      showTotal: 10,
      products: [],
      productsOriginal: [],
      categories: [],
      sortData: "",
      filterModal: false,
      priceFilter: {
        selected: [0, 500],
        min: 0,
        max: 500,
      }

    }
    //this.myRef = React.createRef()
    this.exportRef = React.createRef();
  }
  loadMore = () => {
    let newLoadReq = this.state.showTotal + this.state.showPerPage;
    if (this.state.products.length >= this.state.showTotal) {
      this.setState({
        showTotal: newLoadReq
      })
    }


  }
  toggleFilterModal = () => {
    this.setState({
      filterModal: !this.state.filterModal
    });
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    this.loadProduct()
    this.loadCategories()

  }
  loadProduct = () => {
    fetch('./API/products.json', {
    })
      .then((response) => response.json())
      .then((responseJson) => {
        let tempData = responseJson;
        this.state.products = tempData
        this.setState({
          products: this.state.products,
          productsOriginal: copy(tempData)
        })
      })
      .catch(error => {
        console.log(error);
      })
  }
  loadCategories = () => {
    fetch('./API/categories.json', {
    })
      .then((response) => response.json())
      .then((responseJson) => {
        let tempData = responseJson;
        tempData.forEach((item) => {
          item.checked = false
        })
        this.state.categories = tempData
        this.setState({ categories: this.state.categories })
      })
      .catch(error => {
        console.log(error);
      })
  }
  changeSortData = (e) => {
    let val = e.target.value;
    let name = e.target.name;
    this.state.sortData = val;
    this.setState({
      sortData: this.state.sortData
    })

    this.getFilterDataProductByCategory()
    setTimeout(() => {
      this.getSOrtData()
    }, 10);

  }
  getSOrtData = () => {
    let val = this.state.sortData
    let product = this.getUniqueItem(this.state.products)
    console.log(product)
    if (val === 'lh') {
      this.setState({
        products: this.sortPriceLowToHigh(product)
      })
    }
    else if (val === 'hl') {
      this.setState({
        products: this.sortPriceHighToLow(product)
      })
    }
    else {
      this.setState({
        products: product
      })
    }

  }
  sortPriceLowToHigh = (data) => {
    return data.sort((a, b) => parseFloat(a.price) - parseFloat(b.price));
  }
  sortPriceHighToLow = (data) => {
    return data.sort((a, b) => parseFloat(b.price) - parseFloat(a.price));
  }
  applyFilterPrice = (data) => {
    this.setState({
      priceFilter: data
    })

  }
  applyFilter = (data) => {
    this.setState({
      categories: data
    })
    // this.getFilterDataProductByCategory()
  }
  getFilterDataProductByCategory = () => {
    let getPriceRange = this.state.priceFilter.selected;
    let minPrice = getPriceRange[0]
    let maxPrice = getPriceRange[1]
    let products = []
    let getSelectedCat = this.state.categories.filter((items) => items.checked === true)
    if (getSelectedCat.length > 0) {
      getSelectedCat.forEach((item, index) => {
        console.log(item.name)
        let freshProduct = copy(this.state.productsOriginal)
        let filteredProduct = freshProduct.filter((items) => items.category.some(cat => cat === item.id) && items.price >= minPrice && items.price <= maxPrice);
        console.log(filteredProduct)
        filteredProduct.forEach((elm, elmind) => {
          products.push(elm)
        })
      })
    } else {
      let freshProduct = copy(this.state.productsOriginal)
      let filteredProduct = freshProduct.filter((items) => items.price >= minPrice && items.price <= maxPrice);
      products = filteredProduct
    }
    // console.log(products)
    this.state.products = this.getUniqueItem(products)
    this.setState({
      products: this.state.products
    })

  }
  onlyUnique = (value, index, array) => {
    return array.indexOf(value) === index;
  }
  getUniqueItem = (data) => {
    const keys = ['id', 'name'];
    const filteredData = data.filter((value, index, self) =>
      self.findIndex(v => keys.every(k => v[k] === value[k])) === index
    )
    return filteredData
  }
  getFilterData = () => {
    this.getFilterDataProductByCategory()
    setTimeout(() => {
      this.getSOrtData()
    }, 10);
    this.toggleFilterModal()
  }
  resetFilterData = () => {
    this.state.priceFilter.min = 0;
    this.state.priceFilter.max = 500
    this.state.priceFilter.selected = [0, 500];
    this.state.categories.forEach((item)=>{
      item.checked = false
    })
    this.setState({
      categories:this.state.categories,
      priceFilter: this.state.priceFilter
    })
    setTimeout(() => {
      this.getFilterDataProductByCategory()
    }, 5)
    setTimeout(() => {
      this.getSOrtData()
    }, 10);
    this.toggleFilterModal()
  }
  render() {
    let allproduct = this.state.products.length;
    let showTotal = this.state.showTotal;
    let ifloadMore = false;
    if (allproduct > showTotal) {
      ifloadMore = true
    }
    return (
      <>
        <Helmet>
          <title>Online Wedding Card | Lastest Wedding Card Collection</title>
          <meta name="description"
            content="Find the collection of premium wedding card, simple wedding card, hindu wedding invitation cards." />
            <meta property="og:title" content='Online Wedding Card | Lastest Wedding Card Collection' />
            <meta property="og:description" content='Find the collection of premium wedding card, simple wedding card, hindu wedding invitation cards.' />
        </Helmet>
        <DemoNavbar />
        <main ref="main">
          <section
          style={{backgroundImage:"url(images/inner-banner-bg.webp)", backgroundPosition:"center", backgroundSize:"cover"}}
          className="section pageTitleBG">
            <div className="row m-0">
              <div className="col-12">
                <h3 style={{ fontWeight: "500" }} className="h1 text-center text-uppercase mb-0">Collection</h3>
                <div>

                </div>
              </div>
            </div>
          </section>
          <section style={{ padding: "2rem 0px" }} className="section">
            <div className="row jsutify-content-center align-items-center m-0">
              <div className="col-12 text-center">
                <h1 className="h1 mb-0">
                  Explore Exquisite Wedding Card Collection
                </h1>
                {/* <p className="m-0">
                  Buy bengali wedding card online from Porinoy. Explore our exclusive collection of traditional marriage invitation cards, madhubani bengali wedding cards and many more.
                </p> */}
              </div>
            </div>
          </section>
          <section className="section ">
            <Container fluid>
              <div className="row">
                <div className="col-12">
                  <div className="row align-items-center mb-3">
                    <div className="col-6 col-lg-3 col-xl-3 col-md-4 ">
                      <div class="form-floating ">
                        <select value={this.state.sortData} name="sortData" onChange={(e) => this.changeSortData(e)} className="form-select">
                          <option value="">Default</option>
                          <option value="lh">Price : low to high</option>
                          <option value="hl">Price : high to low</option>
                        </select>
                        <label for="floatingInput">Sorting</label>
                      </div>
                    </div>
                    <div className="col-12 col-lg-3 col-xl-3 col-md-2 d-none d-lg-block d-xl-block d-md-block"></div>
                    <div className="col-12 col-lg-3 col-xl-3 col-md-2 d-none d-lg-block d-xl-block d-md-block"></div>
                    <div className="col-6 col-lg-3 col-xl-3 col-md-4 text-end">
                      <button onClick={this.toggleFilterModal} className="btn btn-lg btn-outline-danger "><i class="fa-solid fa-filter"></i> Filter</button>
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <ProductLoop showPerPage={this.state.showTotal} data={this.state.products} />
                </div>
                {ifloadMore &&
                  <div className="col-12 text-center">
                    <button className="btn btn-danger btn-lg" onClick={(e) => this.loadMore()}>Load More</button>
                  </div>
                }

              </div>
            </Container>
          </section>
        </main>
        <SimpleFooter />
        <Modal centered isOpen={this.state.filterModal} toggle={this.toggleFilterModal} className={this.props.className}>
          <ModalHeader toggle={this.toggleFilterModal}>Filter</ModalHeader>
          <ModalBody>
            <div className="mb-3">
              <CategoryFilter applyFilter={this.applyFilter} data={this.state.categories} />
            </div>
            <div>
              <PriceFilter data={this.state.priceFilter} applyFilterPrice={this.applyFilterPrice} />
            </div>
          </ModalBody>
          <ModalFooter>
            <Button color="danger" onClick={this.getFilterData}>Apply Filter</Button>
            <Button color="dark" onClick={this.resetFilterData}>Reset</Button>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

export default Products;

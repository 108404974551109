import React from "react";

const PrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className+" custPrev"}
      style={{ ...style}}
      onClick={onClick}
    ></div>
  );
};

export default PrevArrow;

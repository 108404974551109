
import React from "react";
import { useParams } from 'react-router-dom'
import { Helmet } from "react-helmet-async";
import ReactHtmlParser from 'react-html-parser';
import {
  Container
} from "reactstrap";

// core components
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import SimpleFooter from "components/Footers/SimpleFooter.js";
function withParams(Component) {
  return props => <Component {...props} params={useParams()} />;
}
class StoriesDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      stories: []

    }

  }

  componentDidMount() {
    window.scrollTo(0, 0);
    let { id } = this.props.params;
    this.loadstories(id)
  }
  loadstories = (id) => {
    fetch('../API/stories.json', {
    })
      .then((response) => response.json())
      .then((responseJson) => {
        let tempData = responseJson;
        let getStories = tempData.filter((item) => item.id === id);
        console.log(getStories)
        this.state.stories = getStories
        this.setState({ stories: this.state.stories })
      })
      .catch(error => {
        console.log(error);
      })
  }
  render() {
    let ifStories = false;
    if (this.state.stories.length) {
      ifStories = true
    }
    return (
      <>
        <DemoNavbar />
        <main ref="main">
          <section
          style={{backgroundImage:"url(images/inner-banner-bg.webp)", backgroundPosition:"center", backgroundSize:"cover"}}
          className="section pageTitleBG">
            <div className="row m-0">
              <div className="col-12">
                <h2 style={{fontWeight:'500'}} className="h2 text-center  mb-0">
                  {ifStories && <>{this.state.stories[0].title}</>}
                </h2>
              </div>
            </div>
          </section>
          <section style={{ paddingBottom: "2.5rem" }} className="section border-top">
            <Container fluid>
              <div className="row align-items-center">
                {ifStories && <div className="col-12 col-lg-5 col-xl-5 mb-4 text-center">
                  <img style={{maxWidth:"100%"}} src={"../" + this.state.stories[0].image} />
                </div>}

                {ifStories && <div className="col-12 col-lg-7 col-xl-7  ">
                {ifStories && <>{this.state.stories[0].subtitle !==''?<h3 className="h3">{this.state.stories[0].subtitle}</h3>:""}</>}
                  {ReactHtmlParser(this.state.stories[0].description)}
                </div>}


              </div>
            </Container>
          </section>
        </main >
        <SimpleFooter />

      </>
    );
  }
}
export default withParams(StoriesDetails);

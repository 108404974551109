import React from "react";

function Loader(){
    return (       
            <div className="position-fixed w-100 h-100 left-0 top-0 d-none">    
              <img className="position-absolute" style={{left:"50%", top:"50%", marginLeft:"-100px", marginTop:"-100px"}} src="images/loader.svg" />          
            </div>
    )      

}
export default Loader;

import React from "react";
import { Helmet } from "react-helmet-async";
import { copy } from 'copy-anything'
import history from "../history";
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardImg,
  FormGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
  Modal, ModalHeader, ModalBody, ModalFooter
} from "reactstrap";

// core components
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import SimpleFooter from "components/Footers/SimpleFooter.js";
import Team from "../components/Team/Index.js";

class Privacy extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      teamlist: [],

    }

  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.loadTeam()
  }
  loadTeam = () => {
    fetch('./API/team.json', {
    })
      .then((response) => response.json())
      .then((responseJson) => {
        let tempData = responseJson;
        this.state.teamlist = tempData
        this.setState({ teamlist: this.state.teamlist })
      })
      .catch(error => {
        console.log(error);
      })
  }
  render() {
    let teamlist = false;
    if (this.state.teamlist.length) {
      teamlist = true
    }
    return (
      <>
      <Helmet>
      <meta property="og:title" content='Porinoy Privacy' />
      </Helmet>
        <DemoNavbar />
        <main ref="main">
          <section
          style={{backgroundImage:"url(images/inner-banner-bg.webp)", backgroundPosition:"center", backgroundSize:"cover"}}
          className="section pageTitleBG">
            <div className="row m-0">
              <div className="col-12">
                <h3 style={{ fontWeight: "500" }} className="h1 text-center text-uppercase mb-0">Privacy Policy</h3>
              </div>
            </div>
          </section>
          <section className="section border-top">
            <Container fluid>
              <div className="row align-items-center upPrivacy">


                <div className="col-12 text-center">
                  <p>
                    Welcome to the Bengali Wedding Card website Porinoy. At Porinoy, we value your privacy and are committed to protecting your personal information. This Privacy Policy explains how we collect, use, disclose, and safeguard your personal information when you use our Website. By accessing or using our Website, you consent to the practices described in this Privacy Policy.

                  </p>
                </div>
                <div className="col-12 ">
                  <div className="h4">1. Information We Collect</div>
                  <p>We may collect the following types of information when you visit our Website:</p>
                  <ul>
                    <li>
                      <span>a. Personal Information:</span> We may collect personal information that you provide voluntarily, such as your name, email address, postal address, phone number, and other contact details when you use our contact forms or subscribe to our newsletters.
                    </li>
                    <li>
                    <span> b. Payment Information:</span> If you make a purchase through our Website, we may collect payment information, including credit card details and billing information. Please note that we do not store your payment information on our servers; it is securely processed by our payment service providers.
                    </li>
                    <li>
                    <span> c. Device and Usage Information:</span> We may automatically collect information about your device and how you use our Website, including your IP address, browser type, operating system, referral URLs, and other usage data.
                    </li>
                  </ul>
                </div>
                <div className="col-12 ">
                  <div className="h4">2. How We Use Your Information</div>
                  <p>We use the information we collect for the following purposes:</p>
                  <ul>
                    <li>
                    <span> a. Provide Services:</span> To provide the services you request, such as processing orders for wedding cards, responding to inquiries, and sending newsletters.
                    </li>
                    <li>
                    <span> b. Improve Website:</span> To analyse user preferences and trends to enhance the functionality and user experience of our Website.
                    </li>
                    <li>
                    <span> c. Marketing:</span> To send you promotional emails or messages about our products and services that may be of interest to you, provided you have given us consent to do so.
                    </li>
                    <li>
                    <span>d. Legal Obligations:</span> To comply with applicable laws, regulations, and legal processes.
                    </li>

                  </ul>
                </div>
                <div className="col-12 ">
                  <div className="h4">3. Information Sharing</div>
                  <p>We do not sell, trade, or rent your personal information to third parties. However, we may share your information with the following entities:</p>
                  <ul>
                    <li>
                    <span> a. Service Providers:</span> We may share your information with third-party service providers who assist us in operating our Website and providing our services. These service providers are bound by confidentiality agreements and are not permitted to use your information for any other purpose.
                    </li>
                    <li>
                    <span>b. Legal Requirements:</span> We may disclose your information if required to do so by law or in response to a valid legal request, such as a court order, subpoena, or government investigation.
                    </li>
                  </ul>
                </div>
                <div className="col-12 ">
                  <div className="h4">4. Security</div>
                  <p>We take reasonable measures to protect your personal information from unauthorised access, disclosure, alteration, and destruction. However, no data transmission over the internet or electronic storage is entirely secure, so we cannot guarantee its absolute security.</p>

                </div>
                <div className="col-12 ">
                  <div className="h4">5. Your Choices</div>
                  <p>You have the right to access, update, correct, or delete your personal information. You can also opt out of receiving marketing communications from us by following the unsubscribe instructions in our emails.</p>

                </div>
                <div className="col-12 ">
                  <div className="h4">6. Cookies</div>
                  <p>We may use cookies and similar tracking technologies to collect information about your browsing activities on our Website. You can control cookies through your browser settings.
                  </p>

                </div>
                <div className="col-12 ">
                  <div className="h4">7. Changes to this Privacy Policy</div>
                  <p>We may update this Privacy Policy from time to time to reflect changes in our practices or for other operational, legal, or regulatory reasons. We encourage you to review this Privacy Policy periodically.

                  </p>

                </div>
                <div className="col-12 ">
                  <div className="h4">8. Contact Us</div>
                  <p>
                    If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at:</p>

                    <p> Phone : +91-9832888069</p>

                    <p> By using our Website, you agree to the terms of this Privacy Policy. If you do not agree with the terms of this Privacy Policy, please do not use our Website.


                  </p>

                </div>
              </div>
            </Container>
          </section>

        </main >
        <SimpleFooter />

      </>
    );
  }
}

export default Privacy;

import React, { useRef, useMemo } from "react";
import { Link,NavLink } from "react-router-dom";
import { saveAs } from 'file-saver';
import { copy } from 'copy-anything'
import JoditEditor from 'jodit-react';
import {
  Button,
  Container,
  Modal, ModalHeader, ModalBody, ModalFooter
} from "reactstrap";
// core components
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import SimpleFooter from "components/Footers/SimpleFooter.js";
class StoriesJsonEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      productEdit: false,
      products: [],
      productsOriginal: [],
      selectedProduct: [],
      selectedProductIndex: null,
      categories: [],
      neproductModal: false,
      newProduct: [{
        "author": "",
        "subtitle": "",
        "title": "",
        "description": "",
        "id": "",
        "image": "",
        "date": ""
      }],
      content: '',
      setContent: '',
      editortext: '',

    }
    this.exportRef = React.createRef();
  }
  newContent = (value,index) => {
    // this.state.selectedProduct[index].description = value;
    // this.setState({
    //   selectedProduct: this.state.selectedProduct
    // })
  }
    newContentNew = (value,index) => {
      // this.state.newProduct[index].description = value;
      // this.setState({
      //   newProduct: this.state.newProduct
      // })
  
    }
  
  newContentblur = (value,index) => {  
    this.state.selectedProduct[index].description = value;
    this.setState({
      selectedProduct: this.state.selectedProduct
    })
  }
  newContentblurNew = (value,index) => {  
    this.state.newProduct[index].description = value;
    this.setState({
      newProduct: this.state.newProduct
    })
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    this.loadProduct();
    this.loadCategories()

  }
  loadProduct = () => {
    fetch('./API/stories.json', {
    })
      .then((response) => response.json())
      .then((responseJson) => {
        let tempData = responseJson;
        this.state.products = tempData
        this.setState({
          products: this.state.products,
          productsOriginal: copy(tempData)
        })
      })
      .catch(error => {
        console.log(error);
      })
  }
  loadCategories = () => {
    fetch('./API/categories.json', {
    })
      .then((response) => response.json())
      .then((responseJson) => {
        let tempData = responseJson;
        tempData.forEach((item) => {
          item.checked = false
        })
        this.state.categories = tempData
        this.setState({ categories: this.state.categories })
      })
      .catch(error => {
        console.log(error);
      })
  }
  editProduct = (ind) => {
    let selpro = [];

    let allproduct = copy(this.state.products)
    selpro.push(allproduct[ind])
    this.setState({
      selectedProduct: selpro,
      productEdit: true,
      selectedProductIndex: ind
    })
  }
  toggle = () => {
    this.setState({
      productEdit: false
    })
  }
  addNewProuctJson = async () => {
    let allproducts = this.state.products;
    allproducts.push(this.state.newProduct[0])
    this.setState({
      products: allproducts
    })
    let jsonfile = JSON.stringify(allproducts, undefined, 2);
    let fileToSave = new Blob([jsonfile], { type: 'application/json' });
    saveAs(fileToSave, 'stories.json');
  }
  updateJson = async () => {
    let allproducts = this.state.products;
    if (this.state.selectedProductIndex !== null) {
      allproducts[this.state.selectedProductIndex] = this.state.selectedProduct[0]
    }
    this.setState({
      products: allproducts
    })
    let jsonfile = JSON.stringify(allproducts, undefined, 2);
    let fileToSave = new Blob([jsonfile], { type: 'application/json' });
    saveAs(fileToSave, 'stories.json');
  }
  generateJson = () => {
    let allproducts = this.state.products;
    let jsonfile = JSON.stringify(allproducts, undefined, 2);
    let fileToSave = new Blob([jsonfile], { type: 'application/json' });
    saveAs(fileToSave, 'stories.json');
  }

  /// update Product
  onchangeCat = (e, ind, catind, catname) => {
    let selProduct = copy(this.state.selectedProduct);
    let getAllCat = selProduct[ind].category;
    let filterALlCat = getAllCat.filter((item) => item === catname);
    if (filterALlCat.length) {
      let updatedCat = getAllCat.filter((item) => item !== catname);
      this.state.selectedProduct[ind].category = updatedCat
      this.setState({
        selectedProduct: this.state.selectedProduct
      })
    } else {
      getAllCat.push(catname);
      this.state.selectedProduct[ind].category = getAllCat
      this.setState({
        selectedProduct: this.state.selectedProduct
      })
    }
  }
  onchangeGalleryImage = (e, ind, galIndex) => {
    this.state.selectedProduct[ind].gallery[0].images[galIndex].url = e.target.value
    this.setState({
      selectedProduct: this.state.selectedProduct
    })
  }
  onchangeGalleryAlt = (e, ind, galIndex) => {
    this.state.selectedProduct[ind].gallery[0].images[galIndex].alt = e.target.value
    this.setState({
      selectedProduct: this.state.selectedProduct
    })
  }
  onchange = (e, ind, keyname) => {
    this.state.selectedProduct[ind][keyname] = e.target.value;
    this.setState({
      selectedProduct: this.state.selectedProduct
    })
  }
  delGalleryItem = (ind, galInd) => {
    let selProduct = copy(this.state.selectedProduct);
    let getAllGallery = selProduct[ind].gallery[0].images;
    let filtergetAllGallery = getAllGallery.filter((_, index) => index !== galInd);
    this.state.selectedProduct[ind].gallery[0].images = filtergetAllGallery;
    this.setState({
      selectedProduct: this.state.selectedProduct
    })
  }
  addupdateproductGallerImage = () => {
    let selProduct = copy(this.state.selectedProduct);
    let getAllGallery = selProduct[0].gallery[0].images;
    let newImage = {
      "url": "",
      "alt": ""
    }
    getAllGallery.push(newImage)
    this.state.selectedProduct[0].gallery[0].images = getAllGallery;
    this.setState({
      selectedProduct: this.state.selectedProduct
    })
  }

  /// new Product
  onchangeCatNew = (e, ind, catind, catname) => {
    let selProduct = copy(this.state.newProduct);
    let getAllCat = selProduct[ind].category;
    let filterALlCat = getAllCat.filter((item) => item === catname);
    if (filterALlCat.length) {
      let updatedCat = getAllCat.filter((item) => item !== catname);
      this.state.newProduct[ind].category = updatedCat
      this.setState({
        newProduct: this.state.newProduct
      })
    } else {
      getAllCat.push(catname);
      this.state.newProduct[ind].category = getAllCat
      this.setState({
        newProduct: this.state.newProduct
      })
    }
  }
  onchangeGalleryImageNew = (e, ind, galIndex) => {
    this.state.newProduct[ind].gallery[0].images[galIndex].url = e.target.value
    this.setState({
      newProduct: this.state.newProduct
    })
  }
  onchangeGalleryAltNew = (e, ind, galIndex) => {
    this.state.newProduct[ind].gallery[0].images[galIndex].alt = e.target.value
    this.setState({
      newProduct: this.state.newProduct
    })
  }
  onchangeNew = (e, ind, keyname) => {
    this.state.newProduct[ind][keyname] = e.target.value;
    this.setState({
      newProduct: this.state.newProduct
    })
  }
  delGalleryItemNew = (ind, galInd) => {
    let selProduct = copy(this.state.newProduct);
    let getAllGallery = selProduct[ind].gallery[0].images;
    let filtergetAllGallery = getAllGallery.filter((_, index) => index !== galInd);
    this.state.newProduct[ind].gallery[0].images = filtergetAllGallery;
    this.setState({
      newProduct: this.state.newProduct
    })
  }
  addnewproductGallerImage = () => {
    let selProduct = copy(this.state.newProduct);
    let getAllGallery = selProduct[0].gallery[0].images;
    let newImage = {
      "url": "",
      "alt": ""
    }
    getAllGallery.push(newImage)
    this.state.newProduct[0].gallery[0].images = getAllGallery;
    this.setState({
      newProduct: this.state.newProduct
    })
  }


  addNewProductModal = () => {
    this.setState({
      neproductModal: true
    })
  }
  addNewProductModalClose = () => {
    this.setState({
      neproductModal: false
    })
  }

  render() {
    let ifProductLoaded = false
    if (this.state.products.length) {
      ifProductLoaded = true
    }
    let ifselProductLoaded = false
    if (this.state.selectedProduct.length) {
      ifselProductLoaded = true
    }
    return (
      <>
        <DemoNavbar />
        <main ref="main">
          <section
          style={{backgroundImage:"url(images/inner-banner-bg.webp)", backgroundPosition:"center", backgroundSize:"cover"}}
          className="section pageTitleBG">
            <div className="row m-0">
              <div className="col-12">
                <div className="h1 text-center text-uppercase mb-0">Stories</div>
                <div className="text-center ">
                <NavLink className="btn btn-danger" to="/admin-login">Back</NavLink>
                  <button onClick={() => this.generateJson()} className="btn btn-danger">Generate JSON</button>
                  <button onClick={() => this.addNewProductModal()} className="btn btn-danger">New Stories</button>
                </div>
              </div>
            </div>
          </section>
          <section className="section border-top">
            <Container fluid>
              <div className="row">
                {ifProductLoaded &&
                  <div className="col-12">
                    <div className="row">
                      {this.state.products.map((item, index) => {

                        return <div key={index} className="col-3 mb-2">
                          <div className="card  border shadow h-100">
                            <div style={{ padding: "10px" }} className="card-header">{item.title}</div>
                            <div style={{ padding: "10px" }} className="card-body">
                              <div className="row">
                                <div className="col-12">
                                  <div className="overflow-hidden position-relative">
                                    <img width="100%" className="opacity" src="images/thumb-container.png" />
                                    <img className="position-absolute start-0 top-0 w-100" src={item.image} />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div style={{ padding: "10px" }} className="card-footer">
                              <button onClick={() => this.editProduct(index)} className="btn btn-danger">Edit</button>
                            </div>
                          </div>
                        </div>
                      })}
                    </div>
                  </div>
                }

              </div>
            </Container>
          </section>
        </main>
        <SimpleFooter />
        <Modal className="fullscreen" centered size="xl" isOpen={this.state.productEdit} toggle={this.toggle}>
          {ifselProductLoaded &&
            <ModalHeader toggle={this.toggle}>Update Story</ModalHeader>
          }
          <ModalBody>
            {ifselProductLoaded &&
              <div className="row">
                {this.state.selectedProduct.map((item, index) => {

                  return <div key={index} className="col-12">
                    <div className="row">
                   
                      <div className="col-12">
                        <div class="form-floating mb-3">
                          <input onChange={(e) => this.onchange(e, index, 'title')} value={item.title} type="text" class="form-control" placeholder="Title" />
                          <label >Title <span className="text-danger">*</span></label>
                        </div>
                      </div>
                      <div className="col-10">
                        <div class="form-floating mb-3">
                          <input onChange={(e) => this.onchange(e, index, 'subtitle')} value={item.subtitle} type="text" class="form-control" placeholder="Sub Title" />
                          <label >Sub Title <span className="text-danger">*</span></label>
                        </div>
                      </div>
                      <div className="col-2">
                        <div class="form-floating mb-3">
                          <input onChange={(e) => this.onchange(e, index, 'id')} value={item.id} type="text" class="form-control" placeholder="Date" />
                          <label >ID# <span className="text-danger">*</span></label>
                        </div>
                      </div>

                      <div className="col-12 mb-3">


                        <label >Description <span className="text-danger">*</span></label>

                        <JoditEditor
                          id='texteditor'
                          value={item.description}
                          config={{
                            readonly: false, // all options from https://xdsoft.net/jodit/play.html
                            height: 300
                          }}


                          onChange={(value)=>this.newContent(value,index)}
                          onBlur={(value)=>this.newContentblur(value,index)}
                        />
                      </div>

                      <div className="col-4">
                        <div class="form-floating mb-3">
                          <input onChange={(e) => this.onchange(e, index, 'image')} value={item.image} type="text" class="form-control" placeholder="Image URL" />
                          <label >Image URL <span className="text-danger">*</span></label>
                          <div>
                            <img className="mt-1 border" src={item.image} height="75" />
                          </div>
                        </div>
                      </div>
                     
                      <div className="col-4">
                        <div class="form-floating mb-3">
                          <input onChange={(e) => this.onchange(e, index, 'date')} value={item.date} type="text" class="form-control" placeholder="Date" />
                          <label >Date <span className="text-danger">*</span></label>
                        </div>
                      </div>
                      <div className="col-4">
                        <div class="form-floating mb-3">
                          <input onChange={(e) => this.onchange(e, index, 'author')} value={item.author} type="text" class="form-control" placeholder="Author" />
                          <label >Author <span className="text-danger">*</span></label>
                        </div>
                      </div>
                    </div>

                  </div>
                })}
              </div>
            }
          </ModalBody>
          <ModalFooter className="justify-content-start">
            <button onClick={() => this.updateJson()} className="btn btn-danger">Update</button>
          </ModalFooter>
        </Modal>
        <Modal className="fullscreen" centered size="xl" isOpen={this.state.neproductModal} toggle={this.addNewProductModalClose}>

          <ModalHeader toggle={this.addNewProductModalClose}>New Story</ModalHeader>

          <ModalBody>

            <div className="row">
              {this.state.newProduct.map((item, index) => {

                return <div key={index} className="col-12">
                  <div className="row">
                    <div className="col-12">
                      <div class="form-floating mb-3">
                        <input onChange={(e) => this.onchangeNew(e, index, 'title')} value={item.title} type="text" class="form-control" placeholder="Title" />
                        <label >Title <span className="text-danger">*</span></label>
                      </div>
                    </div>
                    <div className="col-10">
                      <div class="form-floating mb-3">
                        <input onChange={(e) => this.onchangeNew(e, index, 'subtitle')} value={item.subtitle} type="text" class="form-control" placeholder="Sub Title" />
                        <label >Sub Title <span className="text-danger">*</span></label>
                      </div>
                    </div>
                    <div className="col-2">
                        <div class="form-floating mb-3">
                          <input onChange={(e) => this.onchangeNew(e, index, 'id')} value={item.id} type="text" class="form-control" placeholder="Date" />
                          <label >ID# <span className="text-danger">*</span></label>
                        </div>
                      </div>
                    <div className="col-12 mb-3">


                        <label >Description <span className="text-danger">*</span></label>

                        <JoditEditor
                          id='texteditor'
                          value={item.description}
                          config={{
                            readonly: false, // all options from https://xdsoft.net/jodit/play.html
                            height: 300
                          }}


                          onChange={(value)=>this.newContentNew(value,index)}
                          onBlur={(value)=>this.newContentblurNew(value,index)}
                        />
                      </div>

                    <div className="col-4">
                      <div class="form-floating mb-3">
                        <input onChange={(e) => this.onchangeNew(e, index, 'image')} value={item.image} type="text" class="form-control" placeholder="Image URL" />
                        <label >Image URL <span className="text-danger">*</span></label>
                        <div>
                          <img className="mt-1 border" src={item.image} height="75" />
                        </div>
                      </div>
                    </div>
                    <div className="col-4">
                      <div class="form-floating mb-3">
                        <input onChange={(e) => this.onchangeNew(e, index, 'date')} value={item.date} type="text" class="form-control" placeholder="Date" />
                        <label >Date <span className="text-danger">*</span></label>
                      </div>
                    </div>
                    <div className="col-4">
                      <div class="form-floating mb-3">
                        <input onChange={(e) => this.onchangeNew(e, index, 'author')} value={item.author} type="text" class="form-control" placeholder="Author" />
                        <label >Author <span className="text-danger">*</span></label>
                      </div>
                    </div>
                  </div>

                </div>
              })}
            </div>

          </ModalBody>
          <ModalFooter className="justify-content-start">
            <button onClick={() => this.addNewProuctJson()} className="btn btn-danger">Save</button>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

export default StoriesJsonEdit;

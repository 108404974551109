import React, { useState } from "react";
import googleTransliterate from "google-input-tool";
// import ContextWrapper from './components/Context/ContextWrapper';
// import AppRouter from './components/Routing/AppRouter';
// import HooksHome from './components/Hooks/Home';
// import LifeCycle from './components/LifeCycles/LifeCycles';

export default function Tool() {
    const [translatedText, setTranslatedText] = useState([]);
    const [inputText, setInputText] = useState('');
    function handleChange(e) {
        let currentInput = e.target.value.split(" ");
        setInputText(e.target.value)
        currentInput.forEach((word, index) => {
            getSuggestions(word, index)
        });
        setTimeout(() => {
            if (e.target.value === '') {
                setTranslatedText([])
            }
        }, 1000);

    }
    function getSuggestions(input, index) {
        let sourceText = input;
        let inputLanguage = "bn-t-i0-und";
        let maxResult = 5;
        let request = new XMLHttpRequest();
        // console.log(input.length)
        googleTransliterate(request, sourceText, inputLanguage, maxResult).then(
            function (response) {
                let text = JSON.parse(JSON.stringify(translatedText));
                text[index] = response[0][0];
                setTranslatedText(text);
              //  console.log(inputText)
               
              //  console.log(input, index, text);
                // console.log(text);
            }
        );
    }

    return (
        <div style={{ height: '100vh' }} className="container">
            <div className="row align-items-center h-100">
                <div className="col-12">
                    <h6>Translate to Bengali</h6>
                    <textarea className="form-control" id="textarea" onChange={handleChange} />

                    <p>{translatedText.join(" ")}</p>
                    {/* <AppRouter /> */}
                    {/* <ContextWrapper /> */}
                    {/* <HooksHome /> */}
                    {/* <LifeCycle /> */}
                </div>
            </div>
        </div>
    );
}

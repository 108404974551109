
import React from "react";
import { Helmet } from "react-helmet-async";
import {
  Container
} from "reactstrap";

// core components
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import SimpleFooter from "components/Footers/SimpleFooter.js";
import StoriesLoop from "components/Stories/Index"

class Stories extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      stories: [],
      showTotal: 10,
      showPerPage: 10,
    }

  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.loadstories()
  }
  loadstories = () => {
    fetch('./API/stories.json', {
    })
      .then((response) => response.json())
      .then((responseJson) => {
        let tempData = responseJson;
        this.setState({ stories: tempData })
      })
      .catch(error => {
        console.log(error);
      })
  }
  render() {
    let ifstories = false;
    if (this.state.stories.length) {
      ifstories = true
    }
    return (
      <>
      <Helmet>
          <title>Wedding Invitation Card | Stories</title>
          <meta name="description"
            content="Enjoy the stories of happy couple, satisfied with Porinoy wedding card service." />
            <meta property="og:title" content='Wedding Invitation Card | Stories' />
            <meta property="og:description" content='Enjoy the stories of happy couple, satisfied with Porinoy wedding card service.' />
        </Helmet>
        <DemoNavbar />
        <main ref="main">
          <section
          style={{backgroundImage:"url(images/inner-banner-bg.webp)", backgroundPosition:"center", backgroundSize:"cover"}}
          className="section pageTitleBG">
            <div className="row m-0">
              <div className="col-12">
                <h3 style={{fontWeight:"500"}}  className="h1 text-center text-uppercase mb-0">Stories</h3>
              </div>
            </div>
          </section>
          <section style={{padding:"2rem 0px"}} className="section">
            <div className="row jsutify-content-center align-items-center m-0">
              <div className="col-12 text-center">
                <h1 className="h1 mb-0">Celebration Chronicles: Insights and Inspiration <br className="d-none d-lg-inline d-xl-inline d-md-inline" /> for Your Wedding Card Journey</h1>
              </div>
            </div>
          </section>
          <section style={{ paddingBottom: "2.5rem" }} className="section">
            <Container fluid>

              {ifstories && <StoriesLoop showPerPage={this.state.showTotal} data={this.state.stories} />}

            </Container>
          </section>
        </main >
        <SimpleFooter />

      </>
    );
  }
}

export default Stories;

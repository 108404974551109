
import React from "react";
import { Helmet } from "react-helmet-async";
import { copy } from 'copy-anything'
import history from "../history";
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardImg,
  FormGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
  Modal, ModalHeader, ModalBody, ModalFooter
} from "reactstrap";

// core components
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import SimpleFooter from "components/Footers/SimpleFooter.js";

class Testimonial extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      testimonial: [ ]

    }

  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.loadtestimonial()
  }
  loadtestimonial = () => {
    fetch('./API/testimonial.json', {
    })
      .then((response) => response.json())
      .then((responseJson) => {
        let tempData = responseJson;
        this.state.testimonial = tempData
        this.setState({
          testimonial: this.state.testimonial
        })
      })
      .catch(error => {
        console.log(error);
      })
  }
  render() {
    return (
      <>
      <Helmet>
          <title>Bengali Marriage Invitation Card | Testimonials</title>
          <meta name="description"
            content="Happy to showcase the compliments about Porinoy Bengali Wedding Cards" />
            <meta property="og:title" content='Bengali Marriage Invitation Card | Testimonials' />
            <meta property="og:description" content='Happy to showcase the compliments about Porinoy Bengali Wedding Cards' />
        </Helmet>
        <DemoNavbar />
        <main ref="main">
        <section
        style={{backgroundImage:"url(images/inner-banner-bg.webp)", backgroundPosition:"center", backgroundSize:"cover"}}
        className="section pageTitleBG">
            <div className="row m-0">
              <div className="col-12">
                <h3 style={{fontWeight:"500"}} className="h1 text-center text-uppercase mb-0">Testimonial</h3>
              </div>
            </div>
          </section>
          <section style={{paddingBottom:"2.5rem"}} className="section border-top">
            <Container fluid>
              <div className="row">
                {this.state.testimonial.map((item, index) => {
                  let ifLocation = false
                   if (item.clientLocation !== '') {
                    ifLocation = true
                  }
                  return <div className="col-12 col-lg-4 col-xl-4 col-md-6 mb-4" key={index}> <div className="card h-100 cshadow">
                    <div className="card-body text-center">
                      <div className="row">
                        <div className="col-12">
                          <img className="rounded-circle d-inline" style={{ width: '100px' }} src={item.clientPhoto} />
                        </div>
                        <div className="col-12 mt-4">
                          <span style={{ marginLeft: "-20px", marginTop: "-10px", lineHeight: "normal" }} className="h1 mb-0 position-absolute">&ldquo; </span>
                          <em>{item.description}</em>
                          <span style={{ marginLeft: "10px", marginTop: "10px", lineHeight: "normal" }} className="h1   position-absolute"> &rdquo;</span>
                        </div>
                        <div className="col-12 mt-4">
                          {item.clientName} {ifLocation && <>({item.clientLocation})</>}
                        </div>
                        <div className="col-12 mt-2">
                          @{item.plateform}
                        </div>
                      </div>
                    </div>
                  </div>
                  </div>
                })}

              </div>
            </Container>
          </section>
        </main >
        <SimpleFooter />

      </>
    );
  }
}

export default Testimonial;

import React, { useEffect } from "react";
import { useNavigate } from 'react-router-dom'
import ReactHtmlParser from 'react-html-parser';
const StoriesLoop = ({ data, showPerPage }) => {
  let navigate = useNavigate();
  const goToDetails = (id) => {
    navigate('/stories-details/' + id)
  }

  return (
    <>
      <div className="row">
        {data.map((item, index) => {
          return <div className="col-12 col-lg-4 col-xl-4 col-md-6  mb-4" key={index}> <div className="card h-100 cshadow">
            <div className="card-body">
              <div className="row">
                <div className="col-12 mb-2">
                  <div className="overflow-hidden position-relative">
                    <img width="100%" className="opacity" src="images/thumb-container.png" />
                    <img width="100%" alt="" className="position-absolute start-0 top-0" src={item.image} />
                  </div>
                </div>
                <div className="col-12 ">
                  <h4 style={{fontWeight:"500"}} className="h4">{item.title} </h4>
                </div>
                <div className="col-12 mt-2 ">
                  {item.date} | {item.author}
                </div>

                <div className="col-12 mt-2">
                  <div
                    style={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      width: "100%",
                      height: "110px"
                    }}>
                    {ReactHtmlParser(item.description)}
                  </div>
                </div>

              </div>
            </div>
            <div className="card-footer bg-white border-0 pt-0">
              <button onClick={() => goToDetails(item.id)} className="btn btn-danger">Read More <i class="fa-solid fa-arrow-right-long"></i></button>
            </div>
          </div>
          </div>
        })}

      </div >

    </>
  )
}
export default StoriesLoop;
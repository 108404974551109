
import React from "react";
import { Link, NavLink } from "react-router-dom";
// JavaScript plugin that hides or shows a component based on your scroll
import Headroom from "headroom.js";
// reactstrap components
import {
  Button,
  UncontrolledCollapse,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledDropdown,
  Media,
  NavbarBrand,
  Navbar,
  NavItem,
  Nav,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";
class DemoNavbar extends React.Component {
  componentDidMount() {
    //  let headroom = new Headroom(document.getElementById("navbar-main"));
    // initialise
    // headroom.init();
  }
  state = {
    collapseClasses: "",
    collapseOpen: false,
    categories:[]
  };

  onExiting = () => {
    this.setState({
      collapseClasses: "collapsing-out",
    });
  };

  onExited = () => {
    this.setState({
      collapseClasses: "",
    });
  };
  componentDidMount() {
    this.loadCategories()
  }
  loadCategories = () => {
    fetch('../API/categories.json', {
    })
      .then((response) => response.json())
      .then((responseJson) => {
        let tempData = responseJson;
        tempData.forEach((item) => {
          item.checked = false;          
        })
        this.state.categories = tempData
        this.setState({ 
          categories: this.state.categories 
        })
      })
      .catch(error => {
        console.log(error);
      })
  }
  goToDetails = (id) => {
    window.location = '/product-category/' + id
}
  render() {
    return (
      <>
        <div id="topBar" className="topBar">
          <Container fluid className="h-100">
            <Row className="h-100 align-items-center">
              <Col className="col-lg-4 col-xl-4 col-md-4 col-12 text-center text-lg-left text-xl-left text-md-left" >
                Call us now: +91-9832888069
              </Col>
              <Col className="col-4 text-center d-none d-md-block d-lg-block d-xl-block">
                WELCOME TO PORINOY!
              </Col>
              <Col className="col-4 text-right d-none d-md-block d-lg-block d-xl-block">
                We Ship All Over India


              </Col>
            </Row>
          </Container>

        </div>
        <header className="header-global">
          <Navbar
            className="navbar-main navbar-transparent navbar-light headroom"
            expand="lg"
            id="navbar-main"
          >
            <Container fluid>
              <NavbarBrand className="mr-lg-5 position-relative  d-flex align-items-center justify-content-center" to="/" tag={Link}>
                <span className="logoRound">
                  <img
                    alt="..."
                    src={require("assets/img/logo.png")}
                  />
                </span>

              </NavbarBrand>
              <button className="navbar-toggler float-right" id="navbar_global">
                <span className="navbar-toggler-icon" />
              </button>
              <UncontrolledCollapse
                toggler="#navbar_global"
                navbar
                className={this.state.collapseClasses + " justify-content-end"}
                onExiting={this.onExiting}
                onExited={this.onExited}
              >
                <div className="navbar-collapse-header">
                  <Row>
                    <Col className="collapse-brand" xs="6">
                      <Link to="/">
                        <img
                          alt="..."
                          src={require("assets/img/logo.png")}
                        />
                      </Link>
                    </Col>
                    <Col className="collapse-close" xs="6">
                      <button className="navbar-toggler" id="navbar_global">
                        <span />
                        <span />
                      </button>
                    </Col>
                  </Row>
                </div>
                <Nav className="align-items-lg-center ml-lg-auto main-nav" navbar>
                  <NavItem>
                    <NavLink exact to="/">Home</NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink to="/about-us">About Us</NavLink>
                  </NavItem>
                  {/* <NavItem>
                    <NavLink to="/products">Collection</NavLink>
                  </NavItem> */}
                  <UncontrolledDropdown
                    direction="down"
                    nav>
                    <DropdownToggle
                      caret
                      color="primary"
                      nav>
                      <span className="nav-link-inner--text">Collection</span>
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem to="/products" tag={Link}>
                        All Collection
                      </DropdownItem>
                      {this.state.categories.map((item,index)=>{
                          return <DropdownItem className="text-capitalize" onClick={()=>this.goToDetails(item.id)}>
                         {item.name}
                        </DropdownItem>
                      })}
                     
                    </DropdownMenu>
                  </UncontrolledDropdown>
                  <NavItem>
                    <NavLink to="/testimonials">Testimonials</NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink to="/stories">Stories</NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink to="/contact-us">Contact Us</NavLink>
                  </NavItem>

                </Nav>

              </UncontrolledCollapse>
            </Container>
          </Navbar>
        </header>
      </>
    );
  }
}

export default DemoNavbar;

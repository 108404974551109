
import React from "react";
import { Helmet } from "react-helmet-async";
import { copy } from 'copy-anything'
import history from "../history";
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardImg,
  FormGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
  Modal, ModalHeader, ModalBody, ModalFooter
} from "reactstrap";

// core components
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import SimpleFooter from "components/Footers/SimpleFooter.js";
import Team from "../components/Team/Index.js";

class About extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      teamlist: [],

    }

  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.loadTeam()
  }
  loadTeam = () => {
    fetch('./API/team.json', {
    })
      .then((response) => response.json())
      .then((responseJson) => {
        let tempData = responseJson;
        this.state.teamlist = tempData
        this.setState({ teamlist: this.state.teamlist })
      })
      .catch(error => {
        console.log(error);
      })
  }
  render() {
    let teamlist = false;
    if (this.state.teamlist.length) {
      teamlist = true
    }
    return (
      <>
        <Helmet>
          <title>Marriage Invitation Cards | About Us | Porinoy</title>
          <meta name="description"
            content="Whole team of porinoy work hard to satisfy you with our wedding cards." />
          <meta property="og:title" content='Marriage Invitation Cards | About Us | Porinoy' />
          <meta property="og:description" content='Whole team of porinoy work hard to satisfy you with our wedding cards.' />
        </Helmet>
        <DemoNavbar />
        <main ref="main">
          <section
            style={{ backgroundImage: "url(images/inner-banner-bg.webp)", backgroundPosition: "center", backgroundSize: "cover" }}
            className="section pageTitleBG">
            <div className="row m-0 align-items-center h-100">
              <div className="col-12">
                <h3 style={{ fontWeight: "500" }} className="h1 text-center text-uppercase mb-0">About Us</h3>
              </div>
            </div>
          </section>
          <section style={{ padding: "2rem 0px" }} className="section">
            <div className="row jsutify-content-center align-items-center m-0">
              <div className="col-12 text-center">
                <h1 className="h1 mb-0">
                  Porinoy: Your Source for Elegant Wedding Cards
                </h1>
              </div>
            </div>
          </section>
          <section className="section ">
            <Container fluid>
              <div className="row ">

                <div className="col-12 col-lg-6 col-xl-6 mb-4 mb-lg-0 mb-xl-0 text-center">
                  <img style={{ maxWidth: "100%" }} src="images/stories.jpg" />
                </div>
                <div className="col-12 col-lg-6 col-xl-6">
                  <h2 className="h2">Best online wedding card West Bengal for special occasions</h2>
                  <p>
                    We at Porinoy design breathtakingly beautiful cards for any special occasion that you might have. We Indians have a penchant for rituals, don’t you think? We understand that rituals and functions tend to play a very important role in your lives and that is why we strive hard to provide you with the exact beauties that you need to make your event more special. The designs from Porinoy boast of hard work and excellence and that is one of the reasons why you need to choose us to design the best online wedding card West Bengal for special occasions. From rice ceremonies to weddings, we have got your covered.
                  </p>
                  <p>
                    We are with you every step of the way as we provide you with mesmerizing cards with beautiful designs that would make a smile come across your face. With the most luxurious touches and homely designs, we are here to make your special occasion even more special. On your special day, where you want everyone you love to be with you, we make that dream of yours come true in the best way. With the upcoming festivities, let us be your champions as we help you spread a little bit of joy everywhere with the beauty of the cards that we design.
                  </p>
                </div>

              </div>
            </Container>
          </section>
          <section style={{ background: 'url(images/team-bg.jpg)', backgroundSize: 'cover' }} className="section ">
            <Container className="bestSellerContainer " fluid>
              <Row>
                <Col className="text-center">
                  <div className="h1 text-uppercase">
                    Meet Our Team
                  </div>
                  {teamlist && <Team data={this.state.teamlist} itemSHow={4} itemSHowMD={3} itemSHowsm={2} itemSHowXS={1} />}

                </Col>

              </Row>

            </Container>
          </section>
        </main >
        <SimpleFooter />

      </>
    );
  }
}

export default About;

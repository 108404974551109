import React from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import Cookies from 'universal-cookie';
import history from "../history";
import Home from "./Home.js";
import Stories from "./Stories.js";
import Products from "./Products.js";
import ProductDetails from "./ProductDetails.js";
import StoriesDetails from "./StoriesDetails.js";
import ProductsJsonEdit from "./ProductsJsonEdit.js"
import RecentJsonEdit from "./RecentJsonEdit.js"
import TestimonialJsonEdit from "./TestimonialJsonEdit.js"
import StoriesJsonEdit from "./StoriesJsonEdit.js"
import CategoryJsonEdit from "./CategoryJsonEdit.js"
import Adminlogin from "./Adminlogin.js"
import About from "./About.js";
import Contact from "./Contact.js";
import Testimonial from "./Testimonial.js";
import Terms from "./Terms.js"
import Privacy from "./Privacy.js"
import ProductCat from "./ProductsCategory.js"
import NotFound from "./NotFound.js"
import Tool from "./Tool.js";

const cookies = new Cookies();
class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ifLogin: false,
            loginInfo: {
                username: "",
                password: ""
            }
        }
    }
    componentDidMount() {
      
        window.scrollTo(0, 0);
        let getusername = cookies.get('user');
        let getpassword = cookies.get('password');
        if (getusername && getpassword) {
            if (getusername === 'rahulria1991' && getpassword === 'porinoy@123##') {
                this.setState({
                    ifLogin: true,
                    loginInfo: {username:getusername,password:getpassword}
                })
            }
        }
    }
    render() {

        return (
            <>
                <BrowserRouter history={history}>
                    <Routes>
                        <Route path="/" exact element={<Home />} />
                        <Route path="/404" exact element={<NotFound/>} />
                        <Route path="/tool" exact element={<Tool/>} />
                        <Route path="/terms-condition" exact element={<Terms />} />
                        <Route path="/privacy-policy" exact element={<Privacy />} />
                        <Route path="/admin-login" exact element={<Adminlogin />} />
                        <Route path="/stories" exact element={<Stories />} />
                        <Route path="/stories-details/:id" exact element={<StoriesDetails />} />
                        <Route path="/product-category/:id" exact element={<ProductCat />} />
                        <Route path="/about-us" exact element={<About />} />
                        <Route path="/contact-us" exact element={<Contact />} />
                        <Route path="/testimonials" exact element={<Testimonial />} />
                        <Route path="/products" exact element={<Products />} />
                        <Route  path="/product-details/:id" exact element={<ProductDetails />} />
                        {this.state.ifLogin && <Route path="/generateproductjson" exact element={<ProductsJsonEdit />} />}
                        {this.state.ifLogin && <Route path="/recentWorkproductjson" exact element={<RecentJsonEdit />} />}
                        {this.state.ifLogin && <Route path="/testimonialproductjson" exact element={<TestimonialJsonEdit />} />}
                        {this.state.ifLogin && <Route path="/storiesjson" exact element={<StoriesJsonEdit />} />}
                        {this.state.ifLogin && <Route path="/categoriesjson" exact element={<CategoryJsonEdit />} />}
                        <Route path="*" element={<Navigate to="/404" replace />} />
                    </Routes>
                </BrowserRouter>
            </>
        )
    }
}
export default App

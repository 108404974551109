
import React from "react";
import { Link } from "react-router-dom";
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardImg,
  FormGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
  Modal, ModalHeader, ModalBody, ModalFooter
} from "reactstrap";

// core components
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import SimpleFooter from "components/Footers/SimpleFooter.js";
import Team from "../components/Team/Index.js";

class NotFound extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      teamlist: [],

    }

  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    
    return (
      <>
        <DemoNavbar />
        <main ref="main">
         
          <section className="section border-top">
            <Container fluid>
              <div className="row align-items-center upPrivacy">

              <div className="col-12 text-center">
               <div style={{fontSize:"100px", fontWeight:"700"}} className="h1 text-danger">
                404
               </div>
               <div className="h1">
               Oops. The page you're looking for doesn't exist.
               </div>
               <div className="mt-4">
               <Link className="btn btn-danger" to="/">Back To Home</Link>
               </div>
                </div>
             
              </div>
            </Container>
          </section>
         
        </main >
        <SimpleFooter />

      </>
    );
  }
}

export default NotFound;

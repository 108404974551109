import React, { useEffect } from "react";

const VideoModal = ({ videoUrl }) => {
    return (
        <div>
            {videoUrl !== '' && <iframe
                width="560"
                height="415"
                src={'https://www.youtube.com/embed/'+videoUrl}
                frameborder="0"
                allow="accelerometer"
                allowfullscreen
                style={{ width: '100%' }}
            ></iframe>
    }
         </div>
   
        
    )
}
export default VideoModal;